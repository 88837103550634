import React from 'react';
import TopNav from '../components/navBar/navBar.js';
import RouterScrollToTop from '../components/routerScrollToTop/routerScrollToTop.js';
import Footer from '../components/footer/footer.js';
import CookiesPopUp from '../components/cookiesPopUp/cookiesPopUp.js';
import { connect } from 'react-redux';

const itemLayout = ({ children, cookiesAccepted }) => {
    return (
        <React.Fragment>
            <RouterScrollToTop />
            <TopNav />
            <div className="container__content">{children}</div>
            <Footer />
            {cookiesAccepted ? null : <CookiesPopUp />}
        </React.Fragment>
    );
};

const mapStetToProps = ({ cookies }) => ({
    cookiesAccepted: cookies.get('cookiesAccepted'),
});

export default connect(mapStetToProps)(itemLayout);
