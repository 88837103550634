const NOT_PAID = 'not paid';
const PAID = 'paid';
const PARTLY_PAID = 'partly paid';

exports.NOT_PAID = NOT_PAID;
exports.PAID = PAID;
exports.PARTLY_PAID = PARTLY_PAID;

const RUS_INVOICE_STATUSES = {};
RUS_INVOICE_STATUSES[NOT_PAID] = 'Не оплачено';
RUS_INVOICE_STATUSES[PAID] = 'Оплачено';
RUS_INVOICE_STATUSES[PARTLY_PAID] = 'Частично оплачено';
exports.RUS_INVOICE_STATUSES = RUS_INVOICE_STATUSES;
