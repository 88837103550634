import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
    sending: false,
    done: false,
    sendError: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REQUEST_CALL__START_SENDING:
            return state.set('sending', true);

        case actionTypes.REQUEST_CALL__END_SENDING:
            return state.set('sending', false).set('done', true);

        case actionTypes.REQUEST_CALL__ERROR_SENDING:
            return state
                .set('sending', false)
                .set('done', true)
                .set('sendError', true);

        case actionTypes.REQUEST_CALL__CLEAR:
            return fromJS(initialState);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
