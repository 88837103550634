import axiosIntegrationAuth from '../utils/axiosIntegrationAuth.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default values => async dispatch => {
    dispatch({ type: actionTypes.AUTH_FORM__START_SENDING });

    const { data, status } = await axiosIntegrationAuth({
        method: 'post',
        url: `api/v1/uk/Registration`,
        data: values,
    });

    if (status === OK && data.success === true) {
        dispatch({
            type: actionTypes.AUTH_FORM__DATA_RETRIEVED,
            payload: values,
        });
        return dispatch({ type: actionTypes.AUTH_FORM__END_FIRST_SENDING });
    }

    if (status === OK && data.success === false) {
        dispatch({
            type: actionTypes.AUTH_FORM__MESSAGE_RETRIEVED,
            payload: data.message,
        });
        return dispatch({ type: actionTypes.AUTH_FORM__ERROR_SENDING });
    }

    return dispatch({ type: actionTypes.AUTH_FORM__ERROR_SENDING });
};
