import React from 'react';
import Helmet from 'react-helmet';
import HomeLayouts from '../layouts/homeLayouts.js';
import HomeCompanyInfo from '../components/homeBoxes/homeCompanyInfo.js';
import HomeAdvantages from '../components/homeBoxes/homeAdvantages.js';
import HomeCarousel from '../components/homeBoxes/homeCarousel.js';
import NewsCarousel from '../components/newsCarousel/newsCarousel.js';
import HomeContactInfo from '../components/homeBoxes/homeContactInfo.js';
import Map from '../components/map/map.js';

const HomePage = () => {
    return (
        <HomeLayouts>
            <Helmet>
                <title>Управление жилой недвижимостью - ProHome</title>
                <meta
                    name="description"
                    content="Команда ProHome — это команда профессионалов с современным подходом к обслуживанию жилой недвижимости и обустройству комфортной среды"
                />
            </Helmet>
            <HomeCompanyInfo />
            <HomeAdvantages />
            <HomeCarousel />
            <NewsCarousel whiteBg />
            <HomeContactInfo />
            {/* <Map /> */}
        </HomeLayouts>
    );
};

export default HomePage;
