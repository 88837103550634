import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from '../../utils/moment.js';

const NewsItem = ({
    title,
    article,
    description,
    imageCover,
    createdAt,
    className,
}) => {
    const elementClasses = classNames('item-box', className);
    return (
        <div className={elementClasses}>
            <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                    <img
                        className="img-thumbnail"
                        src={imageCover}
                        alt={title}
                    />
                    <h1 className="item-box__title">{title}</h1>
                    <div className="item-box__date">
                        {moment(createdAt).format('D MMMM YYYY г.')}
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                    <div className="item-box__desc">{description}</div>
                    <div
                        className="item-box__article"
                        dangerouslySetInnerHTML={{ __html: article }}
                    />
                </div>
            </div>
        </div>
    );
};

NewsItem.defaultProps = {
    className: 'col-12',
};

const mapStetToProps = ({ newsItem }) => ({
    title: newsItem.getIn(['data', 'title']),
    article: newsItem.getIn(['data', 'article']),
    description: newsItem.getIn(['data', 'description']),
    imageCover: newsItem.getIn(['data', 'image_cover']),
    createdAt: newsItem.getIn(['data', 'created_at']),
});

export default connect(mapStetToProps)(NewsItem);
