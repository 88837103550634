import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';
import { NOT_RESIDENTIAL_TYPE } from '../constants/estateObjects.js';

export default (PersonalAccountNumber, objectType) =>
    async (dispatch, getState) => {
        if (!PersonalAccountNumber) return null;

        if (NOT_RESIDENTIAL_TYPE.find(i => i === objectType)) {
            return dispatch({ type: actionTypes.COUNTER__CLEAR });
        }

        dispatch({ type: actionTypes.COUNTER__LOADING_START });

        const ClientID = getState().global.auth.get('clientId');

        const { data, status } = await axiosIntegration({
            method: 'post',
            url: 'api/v1/uk/Counter',
            data: {
                ClientID,
                PersonalAccountNumber,
            },
        });

        if (status === OK && data.success === true) {
            dispatch({
                type: actionTypes.COUNTER__CURRENT_RETRIEVED,
                payload: data.data[0],
            });
        }

        return dispatch({ type: actionTypes.COUNTER__LOADING_DONE });
    };
