import React from 'react';
import classNames from 'classnames';
import ImgFromBase from '../imgFromBase/imgFromBase.js';
import PerformerOrder from '../performerOrder/performerOrder.js';

const PerformersItem = ({
    performerObj,
    serviceGroupId,
    serviceId,
    className,
}) => {
    const elementClasses = classNames(
        'service-list__performers-item',
        className,
    );
    const imgBoxClasses = classNames('service-list__performers-img-box', {
        'service-list__performers-img-box--discount':
            performerObj.get('DiscountValue'),
    });

    return (
        <div className={elementClasses}>
            <div className={imgBoxClasses}>
                <ImgFromBase
                    imgUrl={performerObj.getIn(['Performer', 'IconRef'])}
                    imgName={performerObj.getIn(['Performer', 'IconFileName'])}
                    className="service-list__performers-img"
                />
            </div>
            <div className="service-list__performers-info">
                <div className="service-list__performers-title regular-font">
                    {performerObj.getIn(['Performer', 'Name'])}
                </div>
                <div className="service-list__performers-contacts">
                    <a
                        href={`tel:+${performerObj.getIn([
                            'Performer',
                            'Telephone',
                        ])}`}
                    >
                        +{performerObj.getIn(['Performer', 'Telephone'])}
                    </a>
                    <PerformerOrder
                        serviceGroupId={serviceGroupId}
                        serviceId={serviceId}
                        performerId={performerObj.get(['Performer', 'Id'])}
                    />
                </div>
            </div>
        </div>
    );
};

export default PerformersItem;
