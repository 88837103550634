import React from 'react';
import Helmet from 'react-helmet';
import ItemLayouts from '../layouts/itemLayouts.js';
import Header from '../components/header/header.js';

const TermsOfUsePage = () => {
    return (
        <ItemLayouts>
            <Helmet>
                <title>Пользовательское соглашение</title>
            </Helmet>
            <div className="container">
                <div className="row">
                    <Header className="col-12">
                        <h2>Пользовательское соглашение</h2>
                    </Header>
                </div>
            </div>
            <div className="white-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12 content-box">
                            <p className="ql-align-justify">
                                <strong>1. Общие положения</strong>
                            </p>
                            <p className="ql-align-justify">
                                1.1. Общество с ограниченной ответственностью
                                «ПроХоум» (далее — ООО «ПроХоум») предоставляет
                                пользователю сайта (далее пользователь) - доступ
                                к сайту, расположенный по адресу в сети
                                интернет 
                                <a href="/">
                                    <u>https://prohome.ru</u>
                                </a>{' '}
                                на условиях изложенных в настоящем
                                Пользовательском соглашении (далее —
                                «Соглашение», «ПС»). Соглашение вступает в силу
                                с момента выражения Пользователем согласия с его
                                условиями в порядке, предусмотренном п. 1.4
                                Соглашения.
                            </p>
                            <p className="ql-align-justify">
                                1.2. ООО «ПроХоум» предоставляет Пользователям
                                доступ к разделам сайта, включая форму входа в
                                личный кабинет, и его ресурсам. Все существующие
                                на данный момент разделы, а также любое развитие
                                их и/или добавление новых является предметом
                                настоящего Соглашения.
                            </p>
                            <p className="ql-align-justify">
                                1.3. Использование Сайта регулируется настоящим
                                Соглашением, Политикой конфиденциальности,
                                Политикой в отношении обработки и защиты
                                персональных данных. Соглашение может быть
                                изменено ООО «ПроХоум» без какого-либо
                                специального уведомления, новая редакция
                                Соглашения вступает в силу с момента ее
                                размещения в сети Интернет по указанному в
                                настоящем абзаце адресу, если иное не
                                предусмотрено новой редакцией Соглашения.
                                Действующая редакция ПС всегда находится на
                                странице по адресу 
                                <a href="/">
                                    <u>https://prohome.ru</u>
                                </a>
                                .
                            </p>
                            <p className="ql-align-justify">
                                1.4. Начиная использовать какой-либо сервис/его
                                отдельные функции Пользователь считается
                                принявшим условия Соглашения в полном объеме,
                                без всяких оговорок и исключений. В случае
                                несогласия Пользователя с какими-либо из
                                положений Соглашения, Пользователь не вправе
                                использовать сайт ООО «ПроХоум». В случае, если
                                ООО «ПроХоум» внесло какие-либо изменения в
                                Соглашение в порядке, предусмотренной пунктом
                                1.3 Соглашения, с которыми Пользователь не
                                согласен, он обязан прекратить использование
                                сервисов ООО «ПроХоум».
                            </p>
                            <p className="ql-align-justify">
                                <strong>2. Условия использования сайта.</strong>
                            </p>
                            <p className="ql-align-justify">
                                2.1. Пользователь самостоятельно несет
                                ответственность перед третьими лицами за свои
                                действия, связанные с использованием Сайта, в
                                том числе если такие действия приведут к
                                нарушению прав и законных интересов третьих лиц,
                                а также за соблюдение законодательства при
                                использовании Сайта. При использовании Сайта
                                Пользователь не вправе:
                            </p>
                            <ul>
                                <li>
                                    нарушать права третьих лиц и/или причинять
                                    им вред в любой форме;
                                </li>
                                <li>
                                    выдавать себя за другого человека или
                                    представителя организации и/или сообщества
                                    без достаточных на то прав, за администрацию
                                    или владельцев Сайта, а также применять
                                    любые другие формы и способы незаконного
                                    представительства других лиц в сети, а также
                                    вводить пользователей в заблуждение
                                    относительно свойств и характеристик
                                    каких-либо субъектов или объектов;
                                </li>
                                <li>
                                    посылать, передавать или любым другим
                                    способом размещать и/или распространять
                                    контент при отсутствии прав на такие
                                    действия согласно законодательству или
                                    каким-либо договорным отношениям;
                                </li>
                                <li>
                                    загружать, посылать, передавать или любым
                                    другим способом размещать и/или
                                    распространять не разрешенную специальным
                                    образом рекламную информацию, спам, списки
                                    чужих адресов электронной почты, схемы
                                    «пирамид», многоуровневого (сетевого)
                                    маркетинга, системы интернет-заработка и
                                    e-mail бизнесов, «письма счастья», а также
                                    использовать разделы Сайта исключительно для
                                    перенаправления пользователей на страницы
                                    других доменов;
                                </li>
                                <li>
                                    не санкционировано собирать и хранить
                                    персональные данные других пользователей;
                                </li>
                                <li>нарушать нормальную работу Сайта;</li>
                                <li>
                                    содействовать действиям, направленным на
                                    нарушение ограничений и запретов, налагаемых
                                    Соглашением;
                                </li>
                                <li>
                                    другим образом нарушать нормы
                                    законодательства, в том числе нормы
                                    международного права.
                                </li>
                            </ul>
                            <p className="ql-align-justify">
                                <strong>
                                    3. Отсутствие гарантий, ограничение
                                    ответственности
                                </strong>
                            </p>
                            <p className="ql-align-justify">
                                Пользователь использует Сайт на свой собственный
                                риск. Сайт предоставляется «как есть». Сайт не
                                гарантирует, что: соответствует/будет
                                соответствовать требованиям Пользователя;
                                разделы будут предоставляться непрерывно,
                                быстро, надежно и без ошибок; результаты,
                                которые будут получены с использованием
                                разделов, будут точными и надежными и могут
                                использоваться для каких-либо целей или в
                                каком-либо качестве; качество какого-либо
                                продукта, услуги, информации и пр., полученных с
                                использованием сервисов, будет соответствовать
                                ожиданиям Пользователя. Любая информация и/или
                                материалы, доступ к которым Пользователь
                                получает с использованием Сайта, Пользователь
                                может использовать на свой собственный страх и
                                риск и самостоятельно несет ответственность за
                                возможные последствия использования указанной
                                информации и/или материалов, в том числе за
                                ущерб, который это может причинить компьютеру
                                Пользователя или третьим лицам, за потерю данных
                                или любой другой вред. Сайт не несет
                                ответственности за любые виды убытков,
                                произошедшие вследствие использования
                                Пользователем Сайта или отдельных
                                частей/функций/разделов Сайта.
                            </p>
                            <p className="ql-align-justify">
                                <strong>4. Иные положения</strong>
                            </p>
                            <p className="ql-align-justify">
                                Настоящее Соглашение представляет собой договор
                                между Пользователем и Сайтом относительно
                                порядка использования Сайта и заменяет собой все
                                предыдущие соглашения между Пользователем и
                                Сайтом. Настоящее Соглашение регулируется и
                                толкуется в соответствии с законодательством
                                Российской Федерации. Вопросы, не
                                урегулированные настоящим Соглашением, подлежат
                                разрешению в соответствии с законодательством
                                Российской Федерации. Все возможные споры,
                                вытекающие из отношений, регулируемых настоящим
                                Соглашением, разрешаются в порядке,
                                установленном действующим законодательством
                                Российской Федерации, по нормам российского
                                права. Везде по тексту настоящего Соглашения,
                                если явно не указано иное, под термином
                                «законодательство» понимается как
                                законодательство Российской Федерации, так и
                                законодательство места пребывания Пользователя.
                                Ввиду безвозмездности услуг, оказываемых в
                                рамках настоящего Соглашения, нормы о защите
                                прав потребителей, предусмотренные
                                законодательством Российской Федерации, не могут
                                быть применены к отношениям между Пользователем
                                и Сайтом. Если по тем или иным причинам одно или
                                несколько положений настоящего Соглашения будут
                                признаны недействительными или не имеющими
                                юридической силы, это не оказывает влияния на
                                действительность или применимость остальных
                                положений Соглашения.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ItemLayouts>
    );
};

export default TermsOfUsePage;
