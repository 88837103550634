import cookie from 'js-cookie';
import actionTypes from './actionTypes.js';
import axiosIntegrationAuth from '../utils/axiosIntegrationAuth.js';
import { OK } from '../constants/httpStatuses.js';

export default () => async (dispatch, getState) => {
    const ClientID = localStorage.getItem('ClientID');
    const RefreshToken = localStorage.getItem('RefreshToken');
    const AccessToken = cookie.get('AccessToken');
    const { auth } = getState().global;

    if (AccessToken) return null;
    if (!ClientID || !RefreshToken) {
        if (auth) dispatch({ type: actionTypes.AUTH__FAILED });
        return null;
    }

    dispatch({ type: actionTypes.AUTH__START });

    const { data, status } = await axiosIntegrationAuth({
        method: 'post',
        url: `api/v1/uk/RefreshToken`,
        data: { ClientID, RefreshToken },
    });

    if (status === OK && data.success === true) {
        await localStorage.setItem('ClientID', data.data.ClientID);
        await localStorage.setItem(
            'RefreshToken',
            data.data.Token.RefreshToken,
        );
        await cookie.set('AccessToken', data.data.Token.AccessToken, {
            expires: data.data.Token.ExpiresIn / 60 / 60 / 24,
        });

        return dispatch({
            type: actionTypes.AUTH__SUCCESS,
            payload: { clientId: ClientID },
        });
    }

    await localStorage.removeItem('ClientID');
    await localStorage.removeItem('RefreshToken');
    await cookie.remove('AccessToken');

    return dispatch({ type: actionTypes.AUTH__FAILED });
};
