function createThunkMiddleware() {
    return ({ dispatch, getState }) =>
        next =>
        async action => {
            if (typeof action === 'function') {
                try {
                    return await action(dispatch, getState);
                } catch (error) {
                    process.env.NODE_ENV !== 'production' &&
                        console.log('[thunk]');
                    process.env.NODE_ENV !== 'production' &&
                        console.log(error.message);
                    process.env.NODE_ENV !== 'production' && console.log(error);
                    return;
                    /**
                     * Здесь можем выводить сообщения об ошибках
                     */
                }
            }
            return next(action);
        };
}

export default createThunkMiddleware();
