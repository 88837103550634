import actionTypes from '../actions/actionTypes.js';

const initialState = 200;

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HTTP_STATUS__SET:
            return action.payload;

        case '@@router/LOCATION_CHANGE':
            return initialState;

        default:
            return state;
    }
};
