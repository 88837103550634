import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import getInvoice from '../../actions/api.getInvoice.js';
import moment from '../../utils/moment.js';
import ProfileInvoiceSkeleton from './profileInvoiceSkeleton.js';
import isTable from '../../utils/isTable.js';
import CustomModal from '../customModal/customModal.js';
import PayForm from './payForm.js';

const ProfileInvoice = ({
    className,
    dispatch,
    loading,
    personalAccountNumber,
    invoice,
}) => {
    useEffect(() => {
        dispatch(getInvoice(personalAccountNumber));
    }, [personalAccountNumber]);

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const elementClasses = classNames('profile-invoice', className);
    const dateToPay = moment(invoice.get('date_to_pay')).format('D.MM.YYYY');
    const date = moment([
        invoice.get('year'),
        invoice.get('month') - 1,
        1,
    ]).format('MMMM, YYYY');

    if (loading) {
        return (
            <div className={elementClasses}>
                {isTable() ? null : (
                    <div className="profile-info__box-title">Платежи</div>
                )}
                <ProfileInvoiceSkeleton />
            </div>
        );
    }

    const renderBox = () => {
        if (!invoice.get('id')) {
            return (
                <div className="profile-invoice__box-empty">
                    <div className="profile-invoice__box-empty-icon" />
                    <div className="profile-invoice__box-empty-text regular-font">
                        Все счета оплачены
                    </div>
                </div>
            );
        }

        return (
            <Fragment>
                <div className="profile-invoice__box">
                    <div className="profile-invoice__title-box regular-font">
                        <p className="profile-invoice__title">{date}</p>
                        {invoice.get('quittance') ? (
                            <a
                                className="profile-invoice__pdf-link"
                                href={invoice.get('quittance')}
                                target="_blank"
                            >
                                Квитанция PDF
                            </a>
                        ) : null}
                    </div>

                    <div className="profile-invoice__line">
                        <div className="profile-invoice__line-box">
                            <div className="profile-invoice__line-title">
                                {invoice.get('type')}
                            </div>
                            <div className="profile-invoice__line-price regular-font">
                                {invoice.get('amount') || 0}
                                &nbsp;&#8381;
                            </div>
                        </div>
                        <div className="profile-invoice__line-help">
                            {invoice.get('purpose')}
                        </div>
                    </div>

                    <div className="profile-invoice__line">
                        <div className="profile-invoice__line-box">
                            <div className="profile-invoice__line-title">
                                Пеня
                            </div>
                            <div className="profile-invoice__line-price regular-font">
                                {invoice.get('fine') || 0}
                                &nbsp;&#8381;
                            </div>
                        </div>
                    </div>

                    <div className="profile-invoice__line">
                        <div className="profile-invoice__line-box">
                            <div className="profile-invoice__line-title">
                                Задолженность
                            </div>
                            <div className="profile-invoice__line-price regular-font">
                                {invoice.get('debt') || 0}
                                &nbsp;&#8381;
                            </div>
                        </div>
                    </div>

                    <div className="profile-invoice__line regular-font">
                        <div className="profile-invoice__line-box profile-invoice__line-box--total">
                            <div className="profile-invoice__line-title">
                                Итого
                            </div>
                            <div className="profile-invoice__line-price">
                                {invoice.get('total_amount_to_pay') || 0}
                                &nbsp;&#8381;
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-invoice__delimiter-box">
                    <div className="profile-invoice__delimiter regular-font">
                        <div className="profile-invoice__delimiter-date">
                            Оплатить до&nbsp;
                            {dateToPay}
                        </div>
                    </div>
                </div>
                <div className="profile-invoice__pay-box">
                    <button
                        onClick={toggle}
                        className="profile-invoice__pay-btn"
                    >
                        Оплатить
                    </button>
                    <CustomModal
                        isOpen={isOpen}
                        toggle={toggle}
                        title="Оплатить"
                        size="sm"
                    >
                        <PayForm />
                    </CustomModal>
                </div>
            </Fragment>
        );
    };

    return (
        <div className={elementClasses}>
            {isTable() ? null : (
                <div className="profile-info__box-title">Платежи</div>
            )}
            {renderBox()}
            <div className="profile-invoice__link-box">
                <Link to="/profile/invoices">История платежей</Link>
            </div>
        </div>
    );
};

ProfileInvoice.propTypes = {
    className: PropTypes.string,
};

ProfileInvoice.defaultProps = {
    className: 'col-12',
};

const mapStateToProps = ({ clientData, invoices }) => ({
    personalAccountNumber: clientData.getIn([
        'currentDependency',
        'PersonalAccount',
        'Number',
    ]),
    loading: invoices.get('loading'),
    invoice: invoices.get('current'),
});

export default connect(mapStateToProps)(ProfileInvoice);
