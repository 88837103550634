import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CustomModal from '../customModal/customModal.js';
import NewOrderForm from './newOrderForm.js';
import getIncidentTypes from '../../actions/api.getIncidentTypes.js';
import { clearPerformerOrderFrom } from '../../actions/action.formHelpers.js';
import { allFileDrop } from '../../actions/action.fileStorage.js';
import sendOrder from '../../actions/api.sendOrder.js';

const OrdersBtnBox = ({ className, dispatch }) => {
    const elementClasses = classNames('order-btn-box', className);

    useEffect(() => {
        dispatch(getIncidentTypes());
    }, []);

    const [firstIsOpen, setFirstIsOpen] = useState(false);
    const [secondIsOpen, setSecondIsOpen] = useState(false);

    const firstToggle = () => {
        dispatch(clearPerformerOrderFrom());
        dispatch(allFileDrop());
        setFirstIsOpen(!firstIsOpen);
    };

    const secondToggle = () => {
        dispatch(clearPerformerOrderFrom());
        setSecondIsOpen(!secondIsOpen);
    };

    const handleSubmit = values => {
        const fields = {
            Description: values.Description,
            Date: values.Date,
            ClientId: values.ClientId,
            Phone: values.Phone,
            PersonalAccountId: values.dependency.PersonalAccountId,
            ProjectId: values.dependency.ProjectId,
            EstateObjectId: values.dependency.EstateObjectId,
            IncidentTypeId: values.IncidentTypeId,
            IncidentThemeId: values.IncidentThemeId,
        };

        dispatch(sendOrder(fields, true));
    };

    return (
        <div className={elementClasses}>
            <div className="order-btn-box__item">
                <span
                    className="order-btn-box__btn"
                    onClick={firstToggle}
                >
                    Написать обращение
                </span>
                <CustomModal
                    className="auth-modal"
                    isOpen={firstIsOpen}
                    toggle={firstToggle}
                    title="Написать обращение"
                    size="lg"
                >
                    <NewOrderForm onSubmit={handleSubmit} />
                </CustomModal>
            </div>
            <div className="order-btn-box__item">
                <span
                    className="order-btn-box__btn"
                    onClick={secondToggle}
                >
                    Заказать услугу
                </span>
                <CustomModal
                    className="auth-modal"
                    isOpen={secondIsOpen}
                    toggle={secondToggle}
                    title="Заказать услугу"
                    size="lg"
                >
                    <NewOrderForm
                        onSubmit={handleSubmit}
                        isServices
                    />
                </CustomModal>
            </div>
        </div>
    );
};

export default connect()(OrdersBtnBox);
