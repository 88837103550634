import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import DefaultLayout from '../layouts/defaultLayouts.js';
import Header from '../components/header/header.js';
import DependencySelection from '../components/dependencySelection/dependencySelection.js';
import Orders from '../components/orders/orders.js';
import getClientData from '../actions/api.getClientData.js';
import getIncidents from '../actions/api.getIncidents.js';
import {
    clearOrderActive,
    clearOrderHistory,
} from '../actions/action.order.js';
import Spinner from '../components/spinner/spinner.js';
import Breadcrumb from '../components/breadcrumb/breadcrumb.js';

const OrdersPage = ({ dispatch, loading }) => {
    useEffect(() => {
        dispatch(getClientData());
        dispatch(getIncidents({ isActive: true }));
        dispatch(getIncidents({}));

        return () => {
            dispatch(clearOrderActive());
            dispatch(clearOrderHistory());
        };
    }, []);

    const renderContent = () => {
        if (loading) {
            return (
                <div className="col-12">
                    <Spinner />
                </div>
            );
        }

        return (
            <Fragment>
                <DependencySelection className="col-12" />
                <Breadcrumb className="col-12">
                    <Breadcrumb.Item
                        link="/profile"
                        label="Личный кабинет"
                    />
                    <Breadcrumb.Item label="Заявки" />
                </Breadcrumb>
                <Orders className="col-12" />
            </Fragment>
        );
    };

    return (
        <DefaultLayout>
            <Helmet>
                <title>Заявки - ProHome</title>
                <meta
                    name="description"
                    content="Заявки"
                />
            </Helmet>
            <Header className="col-12">
                <h2>Личный кабинет</h2>
            </Header>
            {renderContent()}
        </DefaultLayout>
    );
};

const mapStateToProps = ({ clientData }) => ({
    loading: clientData.get('loading'),
});

export default connect(mapStateToProps)(OrdersPage);
