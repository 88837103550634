import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import httpStatus from './httpStatus.js';
import auth from './auth.js';
import news from './news.js';
import newsItem from './newsItem.js';
import advices from './advices.js';
import complexes from './complexes.js';
import pagination from './pagination.js';
import administrativeObjects from './administrativeObjects.js';
import advice from './advice.js';
import faq from './faq.js';
import requestCallForm from './requestCallForm.js';
import authForm from './authForm.js';
import performerOrderForm from './performerOrderForm.js';
import clientData from './clientData.js';
import invoices from './invoices.js';
import counter from './counter.js';
import services from './services.js';
import editEmailForm from './editEmailForm.js';
import manualCounterForm from './manualCounterForm.js';
import ordersActive from './ordersActive.js';
import ordersHistory from './ordersHistory.js';
import ordersTypes from './ordersTypes.js';
import fileStorage from './fileStorage.js';
import cookies from './cookies.js';

export default history => {
    return combineReducers({
        global: combineReducers({
            httpStatus,
            auth,
            complexes,
            pagination,
        }),
        news,
        newsItem,
        advices,
        advice,
        administrativeObjects,
        faq,
        clientData,
        invoices,
        counter,
        services,
        fileStorage,
        orders: combineReducers({
            active: ordersActive,
            history: ordersHistory,
            types: ordersTypes,
        }),
        router: connectRouter(history),
        form: formReducer,
        formHelper: combineReducers({
            requestCallForm,
            authForm,
            performerOrderForm,
            editEmailForm,
            manualCounterForm,
        }),
        cookies,
    });
};
