import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';
import { SCROLL_LOAD_LIMIT } from '../constants/pagination.js';

const initialState = {
    loading: false,
    redirect: false,
    allUploaded: false,
    current: {},
    data: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INVOICES__CURRENT_RETRIEVED:
            if (!action.payload) return state.set('current', fromJS({}));

            return state.set('current', fromJS(action.payload));

        case actionTypes.INVOICES__DATA_MERGE:
            if (!action.payload.length) {
                return state.set('allUploaded', true);
            }

            if (action.payload.length < SCROLL_LOAD_LIMIT) {
                return state
                    .set(
                        'data',
                        state.get('data').concat(fromJS(action.payload)),
                    )
                    .set('allUploaded', true);
            }

            return state.set(
                'data',
                state.get('data').concat(fromJS(action.payload)),
            );

        case actionTypes.INVOICES__DATA_CLEAR:
            return state.set('data', fromJS([])).set('allUploaded', false);

        case actionTypes.INVOICES__CLEAR:
            return fromJS(initialState);

        case actionTypes.INVOICES__LOADING_START:
            return state.set('loading', true);

        case actionTypes.INVOICES__LOADING_DONE:
            return state.set('loading', false);

        case actionTypes.INVOICES__PAY_START:
            return state.set('redirect', true);

        case actionTypes.INVOICES__PAY_DONE:
            return state.set('redirect', false);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
