import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Map } from 'immutable';
import getClientData from '../actions/api.getClientData.js';
import getServices from '../actions/api.getServices.js';
import DefaultLayout from '../layouts/defaultLayouts.js';
import Breadcrumb from '../components/breadcrumb/breadcrumb.js';
import Header from '../components/header/header.js';
import PerformersList from '../components/performersList/performersList.js';
import Spinner from '../components/spinner/spinner.js';
import deleteUK from '../utils/deleteUK.js';

const PerformerPage = ({
    dispatch,
    loading,
    loadingService,
    usersProjects,
    currentServiceGroup,
    currentService,
    projectId,
    projectName,
}) => {
    useEffect(() => {
        dispatch(getClientData());
        dispatch(getServices(usersProjects));
    }, [usersProjects]);

    const renderContent = () => {
        if (loading || loadingService) {
            return (
                <div className="col-12">
                    <Spinner />
                </div>
            );
        }

        return (
            <Fragment>
                <Breadcrumb className="col-12">
                    <Breadcrumb.Item
                        link="/services"
                        label="Услуги"
                    />
                    <Breadcrumb.Item
                        link={`/services/${currentServiceGroup.get('Id')}`}
                        label={currentServiceGroup.get('Name')}
                    />
                    <Breadcrumb.Item label={currentService.get('Name')} />
                </Breadcrumb>
                <PerformersList
                    serviceGroup={currentServiceGroup}
                    service={currentService}
                    projectId={projectId}
                />
            </Fragment>
        );
    };

    return (
        <DefaultLayout>
            <Helmet>
                <title>Услуги - ProHome</title>
                <meta
                    name="description"
                    content="Услуги"
                />
            </Helmet>
            <Header className="col-12">
                <h4>Услуги управляющей компании</h4>
                <h3>
                    В жилом квартале&nbsp;
                    {deleteUK(projectName)}
                </h3>
            </Header>
            {renderContent()}
        </DefaultLayout>
    );
};

PerformerPage.defaultProps = {
    currentServiceGroup: Map(),
    currentService: Map(),
};

const mapStateToProps = (
    { clientData, services },
    {
        match: {
            params: { id, serviceId },
        },
    },
) => {
    const currentServiceGroup = services
        .get('serviceGroups')
        .find(serviceGroup => serviceGroup.get('Id') === id);

    return {
        usersProjects: clientData.get('usersProjects'),
        currentServiceGroup,
        currentService: currentServiceGroup
            ? currentServiceGroup
                  .get('services')
                  .find(service => service.get('Id') === serviceId)
            : undefined,
        projectId: clientData.getIn([
            'currentDependency',
            'PersonalAccount',
            'EstateObject',
            'Project',
            'Id',
        ]),
        projectName: clientData.getIn([
            'currentDependency',
            'PersonalAccount',
            'EstateObject',
            'Project',
            'Name',
        ]),
        loading: clientData.get('loading'),
        loadingService: services.get('loading'),
    };
};

export default connect(mapStateToProps)(PerformerPage);
