import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';
import { SCROLL_LOAD_LIMIT } from '../constants/pagination.js';
import moment from '../utils/moment.js';
import {
    COUNTER_COLD_WATER_NAME,
    COUNTER_HOT_WATER_NAME,
} from '../constants/counter.js';

const initialState = {
    loading: false,
    allUploaded: false,
    startDate: moment().subtract(12, 'months').toDate(),
    endDate: moment().toDate(),
    current: {},
    currentManualCounter: {},
    history: [],
    chart: {
        labels: [],
        coldWater: [],
        hotWater: [],
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COUNTER__CURRENT_RETRIEVED:
            if (!action.payload) return state.set('current', fromJS({}));

            return state.set('current', fromJS(action.payload));

        case actionTypes.COUNTER__HISTORY_MERGE:
            if (!action.payload.length) {
                return state.set('allUploaded', true);
            }

            if (action.payload.length < SCROLL_LOAD_LIMIT) {
                return state
                    .set(
                        'history',
                        state.get('history').concat(fromJS(action.payload)),
                    )
                    .set('allUploaded', true);
            }

            return state.set(
                'history',
                state.get('history').concat(fromJS(action.payload)),
            );

        case actionTypes.COUNTER__HISTORY_CHART_RETRIEVED:
            return state.set(
                'chart',
                fromJS(action.payload).reduceRight((accum, historyItem) => {
                    return accum
                        .set(
                            'labels',
                            accum
                                .get('labels')
                                .push(
                                    moment([
                                        historyItem.get('year'),
                                        historyItem.get('month') - 1,
                                        1,
                                    ]).format('MM.YYYY'),
                                ),
                        )
                        .set(
                            'coldWater',
                            accum.get('coldWater').push(
                                historyItem
                                    .get('counters')
                                    .reduce((coldWaterValue, counter) => {
                                        if (
                                            counter.get('service_category') !==
                                            COUNTER_COLD_WATER_NAME
                                        )
                                            return coldWaterValue;

                                        return (
                                            coldWaterValue +
                                            counter.get('absolute_difference')
                                        );
                                    }, 0),
                            ),
                        )
                        .set(
                            'hotWater',
                            accum.get('hotWater').push(
                                historyItem
                                    .get('counters')
                                    .reduce((hotWaterValue, counter) => {
                                        if (
                                            counter.get('service_category') !==
                                            COUNTER_HOT_WATER_NAME
                                        )
                                            return hotWaterValue;

                                        return (
                                            hotWaterValue +
                                            counter.get('absolute_difference')
                                        );
                                    }, 0),
                            ),
                        );
                }, fromJS({ labels: [], coldWater: [], hotWater: [] })),
            );

        case actionTypes.COUNTER__HISTORY_CHART_CLEAR:
            return state.set(
                'chart',
                fromJS({ labels: [], coldWater: [], hotWater: [] }),
            );

        case actionTypes.COUNTER__HISTORY_CLEAR:
            return state.set('history', fromJS([])).set('allUploaded', false);

        case actionTypes.COUNTER__START_DATE:
            return state.set('startDate', action.payload);

        case actionTypes.COUNTER__CHOOSE_CURRENT_MANUAL:
            return state.set(
                'currentManualCounter',
                isImmutable(action.payload)
                    ? action.payload
                    : fromJS(action.payload),
            );

        case actionTypes.COUNTER__CLEAR_CURRENT_MANUAL:
            return state.set('currentManualCounter', fromJS({}));

        case actionTypes.COUNTER__END_DATE:
            return state.set('endDate', action.payload);

        case actionTypes.COUNTER__CLEAR:
            return fromJS(initialState);

        case actionTypes.COUNTER__LOADING_START:
            return state.set('loading', true);

        case actionTypes.COUNTER__LOADING_DONE:
            return state.set('loading', false);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
