import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import ItemLayouts from '../layouts/itemLayouts.js';
import getAdvice from '../actions/api.getAdvice.js';
import { clearAdvice } from '../actions/action.advice.js';
import AdviceItem from '../components/adviceItem/adviceItem.js';

const AdvicePage = ({ dispatch, match, title, description }) => {
    useEffect(() => {
        const {
            params: { id },
        } = match;
        dispatch(getAdvice(id));

        return () => dispatch(clearAdvice());
    }, [match]);

    return (
        <ItemLayouts>
            <Helmet>
                <title>{title}</title>
                <meta
                    name="description"
                    content={description}
                />
            </Helmet>
            <div className="white-bg">
                <div className="container">
                    <div className="row">
                        <AdviceItem classNAme="col-12" />
                    </div>
                </div>
            </div>
        </ItemLayouts>
    );
};

const mapStateToProps = ({ advice }) => ({
    title: advice.getIn(['data', 'title']),
    description: advice.getIn(['data', 'description']),
});

export default connect(mapStateToProps)(AdvicePage);
