import React from 'react';
import TopNav from '../components/navBar/navBar.js';
import RouterScrollToTop from '../components/routerScrollToTop/routerScrollToTop.js';
import ComplexSelection from '../components/complexSelection/complexSelection.js';
import Footer from '../components/footer/footer.js';
import CookiesPopUp from '../components/cookiesPopUp/cookiesPopUp.js';
import { connect } from 'react-redux';

const homeLayout = ({ children, cookiesAccepted }) => {
    return (
        <React.Fragment>
            <RouterScrollToTop />
            <TopNav
                white
                withHeader
            />
            <div className="container">
                <div className="row">
                    <ComplexSelection
                        className="col-12"
                        withInfo
                    />
                </div>
            </div>
            {children}
            <Footer notMargin />
            {cookiesAccepted ? null : <CookiesPopUp />}
        </React.Fragment>
    );
};

const mapStetToProps = ({ cookies }) => ({
    cookiesAccepted: cookies.get('cookiesAccepted'),
});

export default connect(mapStetToProps)(homeLayout);
