import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import RequestCall from '../requestCall/requestCall.js';

const Footer = ({ notMargin }) => {
    const elementClasses = classNames('footer', {
        'footer--not-margin': notMargin,
    });
    const currentYear = new Date().getFullYear();

    return (
        <footer className={elementClasses}>
            <div className="container footer__container">
                <div className="row">
                    <div className="col-12">
                        {/* <p>
                            Россия, 115114, г. Москва, Дербеневская наб., д. 7,
                            стр. 10
                        </p>
                        <p>Деловой квартал «Новоспасский»</p> */}
                        <p>
                            <a
                                className="orange-text"
                                href="mailto:info@prohome.ru"
                            >
                                info@prohome.ru
                            </a>
                        </p>
                        <p>+7 495 800 01 02</p>
                        <p>
                            <RequestCall />
                        </p>
                        <p>&copy; ПроХоум 2018 - {currentYear}</p>
                        <p>
                            <Link
                                className="orange-text"
                                to="/privacy-policy"
                            >
                                Политика Конфиденциальности
                            </Link>
                        </p>
                        <p>
                            <Link
                                className="orange-text"
                                to="/terms-of-use"
                            >
                                Пользовательское Соглашение
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.defaultProps = {
    notMargin: false,
};

export default Footer;
