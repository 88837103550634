import React from 'react';
import classNames from 'classnames';
import moment from '../../utils/moment.js';
import { RUS_INVOICE_STATUSES } from '../../constants/invoiceStatuses.js';

const InvoicesItem = ({ invoice, className }) => {
    const elementClasses = classNames('invoices-list__item', className);
    const date = moment([
        invoice.get('year'),
        invoice.get('month') - 1,
        1,
    ]).format('MMMM, YYYY');

    return (
        <div className={elementClasses}>
            <div className="row no-gutters invoices-list__item-box">
                <div className="col-12 col-md-4 col-lg-5 invoices-list__item-date-box">
                    <div className="invoices-list__item-date regular-font">
                        {date}
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-7">
                    <div className="invoices-list__item-line">
                        <div className="invoices-list__item-line-title">
                            {invoice.get('type')}
                        </div>
                        <div className="invoices-list__item-line-price regular-font">
                            {invoice.get('amount') || 0}
                            &nbsp;&#8381;
                        </div>
                    </div>
                    <div className="invoices-list__item-help-line">
                        {invoice.get('purpose')}
                    </div>
                    <div className="invoices-list__item-line">
                        <div className="invoices-list__item-line-title">
                            Пеня
                        </div>
                        <div className="invoices-list__item-line-price regular-font">
                            {invoice.get('fine') || 0}
                            &nbsp;&#8381;
                        </div>
                    </div>
                    <div className="invoices-list__item-line">
                        <div className="invoices-list__item-line-title">
                            Задолженность
                        </div>
                        <div className="invoices-list__item-line-price regular-font">
                            {invoice.get('debt') || 0}
                            &nbsp;&#8381;
                        </div>
                    </div>
                    <div className="invoices-list__item-line invoices-list__item-line--total regular-font">
                        <div className="invoices-list__item-line-title">
                            Итого
                        </div>
                        <div className="invoices-list__item-line-price ">
                            {invoice.get('total_amount') || 0}
                            &nbsp;&#8381;
                        </div>
                    </div>
                    <div className="invoices-list__delimiter" />
                    <div className="invoices-list__status-box regular-font">
                        <div className="invoices-list__status">
                            {RUS_INVOICE_STATUSES[invoice.get('status')]}
                        </div>
                        <div className="invoices-list__pdf">
                            {invoice.get('quittance') ? (
                                <a
                                    className="invoices-list__pdf-link"
                                    href={invoice.get('quittance')}
                                    target="_blank"
                                >
                                    Квитанция PDF
                                </a>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

InvoicesItem.defaultProps = {
    className: 'col-12',
};

export default InvoicesItem;
