import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import CustomModal from '../customModal/customModal.js';
import AuthForm from './authForm.js';
import AuthCodeForm from './authCodeForm.js';
import login from '../../actions/auth.login.js';
import logout from '../../actions/auth.logout.js';
import checkCode from '../../actions/auth.checkCode.js';
import { clearAuthFrom } from '../../actions/action.formHelpers.js';

const Auth = ({
    className,
    dispatch,
    doneFirstStep,
    sendError,
    message,
    auth,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        dispatch(clearAuthFrom());
        setIsOpen(!isOpen);
    };

    const handleSubmit = values => dispatch(login(values));

    const handleLogout = () => dispatch(logout());

    const handleSubmitCode = values => {
        dispatch(checkCode(values.Code));
    };

    const renderCurrentStep = () => {
        if (doneFirstStep && sendError && message) {
            return (
                <AuthCodeForm
                    className="auth-modal__body"
                    onSubmit={handleSubmitCode}
                    errMessage={message}
                />
            );
        }

        if (sendError && message) {
            return (
                <AuthForm
                    className="auth-modal__body"
                    onSubmit={handleSubmit}
                    errMessage={message}
                />
            );
        }

        if (sendError) {
            return (
                <div className="modal-form__container">
                    <div className="modal-form__done-box">
                        <div className="modal-form__error-icon" />
                        <div className="modal-form__done-title">Ошибка</div>
                    </div>
                </div>
            );
        }

        if (doneFirstStep) {
            return (
                <AuthCodeForm
                    className="auth-modal__body"
                    onSubmit={handleSubmitCode}
                />
            );
        }

        return (
            <AuthForm
                className="auth-modal__body"
                onSubmit={handleSubmit}
            />
        );
    };

    if (auth) {
        return (
            <button
                className={`btn auth-btn ${className}`}
                onClick={handleLogout}
            >
                Выход
            </button>
        );
    }

    return (
        <Fragment>
            <button
                className={`btn auth-btn ${className}`}
                onClick={toggle}
            >
                Войти
            </button>
            <CustomModal
                className="auth-modal"
                isOpen={isOpen}
                toggle={toggle}
                title="Авторизация"
                size="lg"
            >
                {renderCurrentStep()}
            </CustomModal>
        </Fragment>
    );
};

Auth.defaultProps = {
    className: '',
};

const mapStetToProps = ({ formHelper, global }) => ({
    done: formHelper.authForm.get('done'),
    doneFirstStep: formHelper.authForm.get('doneFirstStep'),
    message: formHelper.authForm.get('message'),
    sendError: formHelper.authForm.get('sendError'),
    auth: global.auth.get('isAuth'),
});

export default connect(mapStetToProps)(Auth);
