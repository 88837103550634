/**
 * Укорачивает текст до последнего возможного пробела в пределах lenght
 *
 * @param  {String} string текст который надо обрезать
 * @param  {Number} lenght длина, не более которой должен быть текст
 * @param  {Boolean} dots нужны ли точки в конце, default = true
 * @return {String}        возвращает укороченный текст
 */
export default (string, lenght, dots = true) => {
    if (!string) return null;
    if (!lenght) return string;

    if (string.length <= lenght) return string;

    const item = dots ? '$1...' : '$1';

    return string.replace(new RegExp(`(.{${lenght}})[!:\\-,\\.\\s].+`), item);
};
