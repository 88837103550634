import axios from '../utils/axios.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default currentComplexId => async dispatch => {
    if (!currentComplexId)
        return dispatch({ type: actionTypes.ADMINISTRATIVE_OBJECTS__CLEAR });

    dispatch({ type: actionTypes.ADMINISTRATIVE_OBJECTS__LOADING_START });

    const { data, status } = await axios({
        method: 'get',
        url: `api/administrative_objects?housing_complex_id=${currentComplexId}&type_id=1`,
    });

    if (status === OK) {
        dispatch({
            type: actionTypes.ADMINISTRATIVE_OBJECTS__OFFICE_RETRIEVED,
            payload: data.administrative_objects[0],
        });
    }

    return dispatch({ type: actionTypes.ADMINISTRATIVE_OBJECTS__LOADING_DONE });
};
