import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
    loading: false,
    data: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NEWS__DATA_RETRIEVED:
            return state.set('data', fromJS(action.payload));

        case actionTypes.NEWS__LOADING_START:
            return state.set('loading', true);

        case actionTypes.NEWS__LOADING_DONE:
            return state.set('loading', false);

        case actionTypes.NEWS__CLEAR:
            return fromJS(initialState);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
