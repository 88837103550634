import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default values => async (dispatch, getState) => {
    dispatch({ type: actionTypes.MANUAL_COUNTER_FORM__START_SENDING });
    const { CounterValue, CounterCode, ...otherValues } = values;

    const { data, status } = await axiosIntegration({
        method: 'post',
        url: 'api/v1/uk/counters/manual-update',
        data: { ...otherValues,
            Counters: [
                {
                    CounterValue: CounterValue.replace(',', '.'),
                    CounterCode: CounterCode 
                }
            ]
        },
    });

    if (status === OK && data.success === true) {
        dispatch({ type: actionTypes.COUNTER__CLEAR_CURRENT_MANUAL });
    }

    if (status === OK && data.success === false) {
        dispatch({
            type: actionTypes.MANUAL_COUNTER_FORM__MESSAGE_RETRIEVED,
            payload: data.details,
        });
        return dispatch({
            type: actionTypes.MANUAL_COUNTER_FORM__ERROR_SENDING,
        });
    }

    return dispatch({ type: actionTypes.MANUAL_COUNTER_FORM__END_SENDING });
};
