import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default () => async (dispatch, getState) => {
    const ClientID = getState().global.auth.get('clientId');
    const currentDependencyId = getState().clientData.getIn([
        'currentDependency',
        'Id',
    ]);

    if (currentDependencyId) return null;

    dispatch({ type: actionTypes.CLIENT_DATA__LOADING_START });

    const { data, status } = await axiosIntegration({
        method: 'post',
        url: 'api/v1/uk/Dependencies',
        data: { ClientID },
    });

    if (status === OK && data.success === true) {
        dispatch({
            type: actionTypes.CLIENT_DATA__DATA_RETRIEVED,
            payload: {
                lastName: data.data.LastName,
                firstName: data.data.FirstName,
                middleName: data.data.MiddleName,
                email: data.data.Email,
                mobilePhone: data.data.MobilePhone,
                dependencies: data.data.Dependencies,
            },
        });
    }

    return dispatch({ type: actionTypes.CLIENT_DATA__LOADING_DONE });
};
