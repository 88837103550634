import React from 'react';

const HomeCompanyInfo = () => {
    return (
        <div className="container home-company-info">
            <h2>Управляющая компания</h2>

            <div className="home-company-info__box row no-gutters">
                <div className="col-12 col-md-6 home-company-info__img-box">
                    <img
                        src="/static/images/managerCompany.jpg"
                        alt="Команда ProHome"
                    />
                </div>
                <div className="col-12 col-md-6 home-company-info__text">
                    <div className="home-company-info__title">
                        Команда ProHome — это команда профессионалов с
                        современным подходом к обслуживанию жилой недвижимости и
                        обустройству комфортной среды
                    </div>
                    <div className="home-company-info__desc">
                        Мы будем рады помочь во всех вопросах, связанных с
                        заселением и поддержанием высокого уровня эксплуатации
                        вашего дома.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeCompanyInfo;
