import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RouterScrollToTop from '../components/routerScrollToTop/routerScrollToTop.js';
import TopNav from '../components/navBar/navBar.js';
import CookiesPopUp from '../components/cookiesPopUp/cookiesPopUp.js';
import { connect } from 'react-redux';

const ErrorLayouts = ({ children, className = '', cookiesAccepted }) => {
    const layoutClasses = classNames('row', className);

    return (
        <React.Fragment>
            <RouterScrollToTop />
            <TopNav />
            <div className="container container__content">
                <div className={layoutClasses}>{children}</div>
            </div>
            {cookiesAccepted ? null : <CookiesPopUp />}
        </React.Fragment>
    );
};

ErrorLayouts.propTypes = {
    className: PropTypes.string,
};

ErrorLayouts.defaultProps = {
    className: '',
};

const mapStetToProps = ({ cookies }) => ({
    cookiesAccepted: cookies.get('cookiesAccepted'),
});

export default connect(mapStetToProps)(ErrorLayouts);
