import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
    limit: 12,
    page: 1,
    total: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PAGINATION__RETRIEVED:
            return fromJS(action.payload);

        case actionTypes.PAGINATION__UPDATE_PAGE:
            return state.set('page', action.payload);

        case actionTypes.COMPLEXES__CHOOSE_CURRENT:
            return state.set('page', 1);

        case actionTypes.PAGINATION__CLEAR:
            return fromJS(initialState);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
