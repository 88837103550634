import React from 'react';
import { Link } from 'react-router-dom';

const ServicesItem = ({ service, serviceGroupId }) => {
    return (
        <div className="service-list__item">
            <Link to={`/services/${serviceGroupId}/item/${service.get('Id')}`}>
                {service.get('Name')}
            </Link>
        </div>
    );
};

export default ServicesItem;
