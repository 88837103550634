import recoveryToken from '../../actions/auth.recoveryToken.js';
import yandexHit from '../metrica/yandexHit.js';

export default () =>
    ({ dispatch }) =>
    next =>
    action => {
        if (action.type !== '@@router/LOCATION_CHANGE') {
            return next(action);
        }
        yandexHit(action.payload.location.pathname);
        dispatch(recoveryToken());

        return next(action);
    };
