import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import Dotdotdot from 'react-dotdotdot';
import moment from '../../utils/moment.js';

const NewsItem = ({ newsItem, className }) => {
    return (
        <div className={`news-item ${className}`}>
            <div className="news-item__box">
                <Link
                    className="news-item__img"
                    style={{
                        backgroundImage: `url(${newsItem.get('image_cover')})`,
                    }}
                    to={`/news/${newsItem.get('id')}`}
                />
                <div className="news-item__content">
                    <Link
                        className="news-item__title"
                        to={`/news/${newsItem.get('id')}`}
                    >
                        <Dotdotdot clamp={2}>{newsItem.get('title')}</Dotdotdot>
                    </Link>
                    <hr />
                    <Dotdotdot
                        clamp={4}
                        className="news-item__description"
                    >
                        {newsItem.get('description')}
                    </Dotdotdot>
                    <div className="news-item__date">
                        {moment(newsItem.get('published_at')).format(
                            'D MMMM YYYY г.',
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

NewsItem.propTypes = {
    className: PropTypes.string,
    newsItem: PropTypes.instanceOf(Map),
};

NewsItem.defaultProps = {
    className: 'col-4',
    newsItem: Map(),
};

export default NewsItem;
