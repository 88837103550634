import axios from 'axios';
import { apiHost } from '../config/index.js';

const instance = axios.create({
    baseURL: apiHost,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    validateStatus: function (status) {
        return status >= 200 && status < 500; // возможно понадобится более жесткое условие
    },
});

export default async ({ method = 'get', data = {}, url, headers = {} }) => {
    // муть с бекендом
    // без токена не могу получить ссылку на оплату
    // с токеном не могу получить faq и все новости
    //const cmsToken = localStorage.getItem('cmsToken');

    return await instance({
        url,
        method,
        data,
        headers: {
            ...headers,
            //Authorization: `Bearer ${cmsToken}`,
        },
    });
};
