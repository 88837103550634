const production = process.env.REACT_APP_ENV === 'production';

module.exports = {
    apiHost: production
        ? 'https://api.prohome.ru/'
        : 'https://test.api.prohome.kay-com.net/',

    integrationApiHost: production
        ? 'https://gw.prohome.ru/'
        : 'https://srv-integ-test.prohome.ru/',

    login: 'psn_mobile',
    password: 'password',
};
