import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

const FormComplexSelection = ({
    complexes,
    value,
    thingsChanged,
    error,
    touched,
}) => {
    const selectClasses = classNames('form-complex-selection__select', {
        'is-invalid': touched && error,
    });

    const transformComplexes = () => {
        return complexes.toJS().map(complex => ({
            value: complex.IntegrationId,
            label: complex.Name,
        }));
    };

    const handleChange = option => {
        thingsChanged(option.value);
    };

    const findCurrentComplex = () => {
        const currentComplex = complexes.find(
            complex => value === complex.get('IntegrationId'),
        );

        return currentComplex
            ? {
                  value: currentComplex.get('IntegrationId'),
                  label: currentComplex.get('Name'),
              }
            : null;
    };

    return (
        <div className="form-complex-selection__container">
            <Select
                className={selectClasses}
                classNamePrefix="select-form-lg"
                value={findCurrentComplex()}
                placeholder="Выбрать жилой комплекс"
                options={transformComplexes()}
                onChange={handleChange}
                isSearchable={false}
            />
        </div>
    );
};

const mapStateToProps = ({ global }) => ({
    complexes: global.complexes.get('data'),
});

export default connect(mapStateToProps)(FormComplexSelection);
