import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

const FormDependencySelection = ({
    dependencies,
    option,
    thingsChanged,
    error,
    touched,
}) => {
    const selectClasses = classNames('form-complex-selection__select', {
        'is-invalid': touched && error,
    });

    const transformComplexes = () => {
        return dependencies.map(dependency => ({
            value: dependency.getIn(['PersonalAccount', 'EstateObject', 'Id']),
            label:
                dependency.getIn([
                    'PersonalAccount',
                    'EstateObject',
                    'SubType',
                ]) +
                ': ' +
                dependency.getIn([
                    'PersonalAccount',
                    'EstateObject',
                    'Address',
                ]) +
                ', ' +
                dependency.getIn(['PersonalAccount', 'EstateObject', 'Number']),
            PersonalAccountId: dependency.getIn(['PersonalAccount', 'Id']),
            ProjectId: dependency.getIn([
                'PersonalAccount',
                'EstateObject',
                'Project',
                'Id',
            ]),
            EstateObjectId: dependency.getIn([
                'PersonalAccount',
                'EstateObject',
                'Id',
            ]),
        }));
    };

    const handleChange = options => {
        thingsChanged(options);
    };

    const findCurrentComplex = () => {
        const currentComplex = dependencies.find(dependency => {
            if (!option) return null;
            return (
                option.value ===
                dependency.getIn(['PersonalAccount', 'EstateObject', 'Id'])
            );
        });

        return currentComplex
            ? {
                  value: currentComplex.getIn([
                      'PersonalAccount',
                      'EstateObject',
                      'Id',
                  ]),
                  label:
                      currentComplex.getIn([
                          'PersonalAccount',
                          'EstateObject',
                          'SubType',
                      ]) +
                      ': ' +
                      currentComplex.getIn([
                          'PersonalAccount',
                          'EstateObject',
                          'Address',
                      ]) +
                      ', ' +
                      currentComplex.getIn([
                          'PersonalAccount',
                          'EstateObject',
                          'Number',
                      ]),
                  PersonalAccountId: currentComplex.getIn([
                      'PersonalAccount',
                      'Id',
                  ]),
                  ProjectId: currentComplex.getIn([
                      'PersonalAccount',
                      'EstateObject',
                      'Project',
                      'Id',
                  ]),
                  EstateObjectId: currentComplex.getIn([
                      'PersonalAccount',
                      'EstateObject',
                      'Id',
                  ]),
              }
            : null;
    };

    return (
        <div className="form-complex-selection__container">
            <Select
                className={selectClasses}
                classNamePrefix="select-form-lg"
                value={findCurrentComplex()}
                placeholder="Адрес"
                options={transformComplexes()}
                onChange={handleChange}
                isSearchable={false}
            />
        </div>
    );
};

const mapStateToProps = ({ clientData }) => ({
    dependencies: clientData.get('dependencies'),
});

export default connect(mapStateToProps)(FormDependencySelection);
