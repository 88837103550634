import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default usersProjects => async (dispatch, getState) => {
    const done = getState().services.get('done');

    if (!usersProjects.size || done) return null;

    dispatch({ type: actionTypes.SERVICES__LOADING_START });

    const { data, status } = await axiosIntegration({
        method: 'post',
        url: 'api/v1/uk/MPServicesList',
    });

    if (status === OK && data.success === true) {
        dispatch({
            type: actionTypes.SERVICES__DATA_RETRIEVED,
            payload: {
                serviceGroups: data.data.ServiceGroups,
                usersProjects,
            },
        });
    }

    return dispatch({ type: actionTypes.SERVICES__LOADING_DONE });
};
