import React from 'react';
import Helmet from 'react-helmet';
import ItemLayouts from '../layouts/itemLayouts.js';
import Header from '../components/header/header.js';

const CookiesPolicyPage = () => {
    return (
        <ItemLayouts>
            <Helmet>
                <title>Политика использования файлов cookie</title>
            </Helmet>
            <div className="container">
                <div className="row">
                    <Header className="col-12">
                        <h2>Политика использования файлов cookie</h2>
                    </Header>
                </div>
            </div>
            <div className="white-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12 content-box">
                            <p className="ql-align-justify">
                                Работая на сайте{' '}
                                <a href="/">
                                    <u>https://prohome.ru</u>
                                </a>{' '}
                                и/или используя «Личный кабинет», Вы
                                соглашаетесь с настоящей политикой, в том числе
                                с тем, что Оператор ООО «ПроХоум» (ООО
                                «ПроХоум») может использовать файлы cookie для:
                            </p>
                            <ul>
                                <li>улучшения работы сайта и/или системы;</li>
                                <li>
                                    адаптации сайта и/или системы к
                                    предпочтениям пользователя;
                                </li>
                                <li>
                                    учета анонимных сводных статистических
                                    данных, которые показывают, как пользователи
                                    используют сайт или систему;
                                </li>
                                <li>
                                    последующей обработки статистических
                                    обезличенных данных системами Google
                                    Analytics, Яндекс.Метрика и др., а также для
                                    передачи таких данных третьим лицам в целях
                                    проведения исследований, выполнения работ
                                    или оказания услуг.
                                </li>
                            </ul>
                            <p className="ql-align-justify">
                                <strong>Что такое файлы cookie</strong>
                            </p>
                            <p className="ql-align-justify">
                                <strong>Файлы cookie</strong> – текстовые файлы
                                небольшого размера, которые сохраняются на Вашем
                                устройстве (персональном компьютере, ноутбуке,
                                планшете, мобильном телефоне и т.п.), когда вы
                                посещаете сайты в сети «Интернет».
                            </p>
                            <p className="ql-align-justify">
                                Кроме того, при посещении сайта или системы в
                                сети «Интернет» происходит автоматический сбор
                                иных данных, в том числе: технических
                                характеристик устройства, IP-адреса, информации
                                об используемом браузере и языке, даты и времени
                                доступа к сайту, адресов запрашиваемых страниц
                                сайта и иной подобной информации.
                            </p>
                            <p className="ql-align-justify">
                                <strong>
                                    Какие виды файлов cookie используются
                                </strong>
                            </p>
                            <p className="ql-align-justify">
                                В зависимости от используемых Вами браузера и
                                устройства используются разные наборы файлов
                                cookie, включающие в себя строго необходимые,
                                эксплуатационные, функциональные и аналитические
                                файлы cookie.
                            </p>
                            <p className="ql-align-justify">
                                Типы файлов cookie, используемых на сайте и/или
                                в системе, можно разделить на несколько
                                категорий:
                            </p>
                            <ul>
                                <li>
                                    Сессионные cookie: для конкретного
                                    посещения, ограничены отправкой
                                    идентификаторов сеансов (случайные числа,
                                    сгенерированные сервером), поэтому Вам не
                                    нужно повторно вводить информацию при
                                    переходе на новую страницу или выходить из
                                    нее. Файлы cookie сеанса не сохраняются на
                                    Вашем устройстве и не удаляются при закрытии
                                    браузера;
                                </li>
                                <li>
                                    Постоянные файлы cookie: для записи
                                    информации о Ваших предпочтениях и
                                    сохранения в кеше браузера или в мобильном
                                    устройстве;
                                </li>
                                <li>
                                    Основные файлы cookie: мы можем использовать
                                    важные файлы cookie для аутентификации
                                    пользователей и предотвращения
                                    мошеннического использования учетных записей
                                    пользователей;
                                </li>
                                <li>
                                    Сторонние файлы cookie: помещены кем-то
                                    другим, кроме нас, которые могут собирать
                                    данные на нескольких сайтах или сеансах.
                                </li>
                            </ul>
                            <p className="ql-align-justify">
                                <strong>Как управлять файлами cookie</strong>
                            </p>
                            <p className="ql-align-justify">
                                Используемые Вами браузер и (или) устройство
                                могут позволять вам блокировать, удалять или
                                иным образом ограничивать использование файлов
                                cookie. Но файлы cookie являются важной частью
                                сайта и/или системы, поэтому блокировка,
                                удаление или ограничение их использования может
                                привести к тому, что Вы будете иметь доступ не
                                ко всем функциям сайта и/или системы.
                            </p>
                            <p className="ql-align-justify">
                                Чтобы узнать, как управлять файлами cookie с
                                помощью используемых Вами браузера или
                                устройства, Вы можете воспользоваться
                                инструкцией, предоставляемой разработчиком
                                браузера или производителем устройства.
                            </p>
                            <p className="ql-align-justify">
                                <strong>Контакты</strong>
                            </p>
                            <p className="ql-align-justify">
                                В случае возникновения каких-либо вопросов,
                                пожалуйста, свяжитесь с нами по электронной
                                почте: 
                                <a href="mailto:info@prohome.ru">
                                    <u>info@prohome.ru</u>
                                </a>{' '}
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ItemLayouts>
    );
};

export default CookiesPolicyPage;
