import { yandexMetricaId } from './config.js';
/**
 * Реагирует на изменение URL
 * помогает счетчику узнавать когда меняется урл страниц
 */
export default function yandexHit(url) {
    if (typeof ym === 'function') {
        // eslint-disable-next-line no-undef
        ym(yandexMetricaId, 'hit', url);
    } else {
        console.log('Yandex Metrica не загружена:', `NEW URL - ${url}`);
    }
}
