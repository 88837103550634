import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from '../auth/auth.js';
import NavBarMobileApp from './navBarMobileApp.js';

const NavBar = ({ white, auth, withHeader }) => {
    const [mobileIsOpen, setMobileIsOpen] = useState(false);
    const barClasses = classNames('nav-bar', {
        'nav-bar--with-bg': withHeader,
    });

    const overlayClasses = classNames(
        'nav-bar__mobile-overlay nav-bar__mobile',
        {
            open: mobileIsOpen,
            white,
        },
    );

    const containerClasses = classNames('container nav-bar__container', {
        hidden: mobileIsOpen,
        white,
    });

    const toggle = () => setMobileIsOpen(!mobileIsOpen);

    const renderMenu = () => {
        return (
            <ul className="nav-bar__ul">
                <li className="nav-bar__item ">
                    <Link
                        to=""
                        className="nav-bar__link"
                    >
                        О компании
                    </Link>
                </li>
                <li className="nav-bar__item">
                    <NavLink
                        to="/news"
                        className="nav-bar__link"
                    >
                        Новости
                    </NavLink>
                </li>
                {auth ? (
                    <li className="nav-bar__item">
                        <NavLink
                            to="/services"
                            className="nav-bar__link"
                        >
                            Услуги
                        </NavLink>
                    </li>
                ) : null}
                <li className="nav-bar__item">
                    <NavLink
                        to="/usefulinformation"
                        className="nav-bar__link"
                    >
                        Полезная информация
                    </NavLink>
                </li>
                <li className="nav-bar__item">
                    <NavLink
                        to="/contacts"
                        className="nav-bar__link"
                    >
                        Контакты
                    </NavLink>
                </li>
                <li className="nav-bar__item">
                    <NavBarMobileApp className="nav-bar__link" />
                </li>
                {auth ? (
                    <li className="nav-bar__item">
                        <NavLink
                            to="/profile"
                            className="nav-bar__link"
                        >
                            Личный кабинет
                        </NavLink>
                    </li>
                ) : null}
                <li className="nav-bar__item">
                    <NavLink
                        to="/faq"
                        className="nav-bar__link"
                    >
                        FAQ
                    </NavLink>
                </li>
            </ul>
        );
    };

    const renderHeaderContent = () => {
        if (!withHeader) return null;

        return (
            <div className="home-header">
                <div className="home-header__content">
                    <h1>ProHome</h1>
                    <h3>Добро пожаловать домой</h3>
                </div>
            </div>
        );
    };

    return (
        <nav className={barClasses}>
            <div className={containerClasses}>
                <Link
                    className="nav-bar__logo"
                    to="/"
                >
                    <img
                        src="/static/images/logo.svg"
                        className="d-none d-md-block default-logo"
                        alt="ProHome"
                    />
                    <img
                        src="/static/images/logo-white-bg.svg"
                        className="d-block d-md-none default-logo"
                        alt="ProHome"
                    />
                    <img
                        src="/static/images/logo-opacity.svg"
                        className="home-logo"
                        alt="ProHome"
                    />
                </Link>
                <div className="nav-bar__box">
                    <div className="nav-bar__contact">
                        <div className="nav-bar__contact-phone">
                            +7 495 800 01 02
                        </div>
                    </div>
                    <div className="nav-bar__menu nav-bar__desktop">
                        {renderMenu()}
                        <Auth className="nav-bar__desktop" />
                    </div>
                </div>
                <div className="nav-bar__btn-box">
                    <div
                        className="nav-bar__hamburger nav-bar__mobile"
                        onClick={toggle}
                    >
                        <div className="nav-bar__hamburger-line" />
                        <div className="nav-bar__hamburger-line" />
                        <div className="nav-bar__hamburger-line" />
                    </div>
                </div>
            </div>

            <div className={overlayClasses}>
                <div className="container nav-bar__container">
                    <div className="nav-bar__logo">
                        <img
                            src="/static/images/logo-white-bg.svg"
                            alt="ProHome"
                        />
                    </div>
                    <div className="nav-bar__box">
                        <div className="nav-bar__contact">
                            <div className="nav-bar__contact-phone">
                                +7 495 800 01 02
                            </div>
                        </div>
                    </div>
                    <div className="nav-bar__btn-box">
                        <div
                            className="nav-bar__hamburger-close nav-bar__mobile"
                            onClick={toggle}
                        />
                    </div>
                </div>
                <div className="container nav-bar__container-mobile">
                    {renderMenu()}
                    <Auth />
                </div>
            </div>
            {renderHeaderContent()}
        </nav>
    );
};

NavBar.propTypes = {
    white: PropTypes.bool,
    withHeader: PropTypes.bool,
};

NavBar.defaultProps = {
    white: false,
    withHeader: false,
};

const mapStateToProps = ({ global }) => ({
    auth: global.auth.get('isAuth'),
});

export default connect(mapStateToProps)(NavBar);
