import React from 'react';
import { get } from 'lodash';
import { COUNTER_TYPES } from '../../constants/counter.js';

const CounterValue = ({ counter }) => {
    const currentType = get(COUNTER_TYPES, counter.get('service_category'));

    if (!currentType) return null;

    return (
        <div className={`history-item__counter ${currentType.type}`}>
            <div className="history-item__counter-name">
                {currentType.name}
                &nbsp;
                {counter.get('tariff') ? `(${counter.get('tariff')})` : null}
            </div>
            <div className="history-item__counter-value regular-font">
                {counter.get('absolute_difference')}
                &nbsp;
                {currentType.measure}
            </div>
        </div>
    );
};

export default CounterValue;
