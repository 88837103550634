import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import ResendingSms from '../resendingSms/resendingSms.js';

const validate = values => {
    const errors = {};

    if (!values.Code) {
        errors.Code = 'Required';
    }

    return errors;
};

const renderField = ({
    input,
    placeholder,
    type,
    className,
    meta: { touched, error },
}) => {
    const elementClasses = classNames(className, {
        'is-invalid': touched && error,
    });

    return (
        <input
            {...input}
            type={type}
            className={elementClasses}
            placeholder={placeholder}
        />
    );
};

const AuthForm = ({ handleSubmit, sending, className, errMessage }) => {
    const containerClasses = classNames('modal-form__container', className);

    const renderErr = () => {
        if (errMessage) {
            return (
                <div className="form-check modal-form__help-err">
                    {errMessage}
                </div>
            );
        }

        return null;
    };

    return (
        <div className={containerClasses}>
            <form
                onSubmit={handleSubmit}
                className="modal-form row"
            >
                <div className="form-group col-12 col-md-6 offset-md-3">
                    <Field
                        name="Code"
                        component={renderField}
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Код из SMS"
                    />
                </div>

                <div className="col-12 modal-form__footer">
                    {renderErr()}
                    <div>
                        <ResendingSms time={30} />
                    </div>
                    <div className="form-group modal-form__submit-box">
                        <button
                            className="btn btn-lg btn-orange"
                            type="submit"
                            disabled={sending}
                        >
                            Войти
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const mapStetToProps = ({ formHelper }) => ({
    sending: formHelper.authForm.get('sending'),
});

export default connect(mapStetToProps)(
    reduxForm({ form: 'AuthCodeForm', validate })(AuthForm),
);
