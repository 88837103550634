import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

const FormOrderTypeSelection = ({
    types,
    value,
    thingsChanged,
    error,
    touched,
    placeholder,
}) => {
    const selectClasses = classNames('form-complex-selection__select', {
        'is-invalid': touched && error,
    });

    const transformTypes = () => {
        return types.map(type => ({
            value: type.get('Id'),
            label: type.get('Name'),
        }));
    };

    const handleChange = option => {
        thingsChanged(option.value);
    };

    const findCurrentType = () => {
        const currentType = types.find(type => {
            if (!value) return null;
            return value === type.get('Id');
        });

        return currentType
            ? { value: currentType.get('Id'), label: currentType.get('Name') }
            : null;
    };

    return (
        <div className="form-complex-selection__container">
            <Select
                className={selectClasses}
                classNamePrefix="select-form-lg"
                value={findCurrentType()}
                placeholder={placeholder}
                options={transformTypes()}
                onChange={handleChange}
                isSearchable={false}
            />
        </div>
    );
};

const mapStateToProps = ({ orders }) => ({
    types: orders.types.get('data'),
});

export default connect(mapStateToProps)(FormOrderTypeSelection);
