import React from 'react';
import { List } from 'immutable';
import ImgFromBase from '../imgFromBase/imgFromBase.js';
import ServicesItem from './servicesItem.js';

const ServicesList = ({ serviceGroup, projectId }) => {
    const filterServices = () => {
        const services = serviceGroup.get('services');
        if (!services) return List();

        return services.reduce((accum, service) => {
            if (
                service
                    .get('projectsIds')
                    .find(currProjectId => currProjectId === projectId)
            ) {
                return accum.push(service);
            }
            return accum;
        }, List());
    };

    return (
        <div className="service-list col-12">
            <div className="service-list__box">
                <div className="service-list__header">
                    <div className="service-list__img-box">
                        <ImgFromBase
                            imgUrl={serviceGroup.get('IconRef')}
                            imgName={serviceGroup.get('IconFileName')}
                            className="service-list__img"
                        />
                    </div>
                    <p className="service-list__title regular-font">
                        {serviceGroup.get('Name')}
                    </p>
                </div>
                <div className="service-list__content">
                    {filterServices().map(service => (
                        <ServicesItem
                            key={service.get('Id')}
                            service={service}
                            serviceGroupId={serviceGroup.get('Id')}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServicesList;
