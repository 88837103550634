import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const validate = values => {
    const errors = {};

    if (!values.LName) {
        errors.LName = 'Required';
    }

    if (!values.FName) {
        errors.FName = 'Required';
    }

    if (!values.Phone) {
        errors.Phone = 'Required';
    }

    return errors;
};

const renderField = ({
    input,
    placeholder,
    type,
    className,
    meta: { touched, error },
}) => {
    const elementClasses = classNames(className, {
        'is-invalid': touched && error,
    });
    return (
        <input
            {...input}
            type={type}
            className={elementClasses}
            placeholder={placeholder}
        />
    );
};

const AuthForm = ({ handleSubmit, sending, errMessage, className }) => {
    const containerClasses = classNames('modal-form__container', className);

    const normalizePhone = value => {
        if (value === '8') return '7';
        return value;
    };

    const renderAgree = () => {
        if (errMessage) {
            return (
                <div className="form-check modal-form__help-err">
                    {errMessage}
                </div>
            );
        }

        return (
            <div className="form-check modal-form__agree">
                Продолжив авторизацию, Вы соглашаетесь с пользовательским
                соглашением и&nbsp;
                <Link
                    to="/privacy-policy"
                    target="_blank"
                >
                    политикой конфиденциальности
                </Link>
            </div>
        );
    };

    return (
        <div className={containerClasses}>
            <form
                onSubmit={handleSubmit}
                className="modal-form row"
            >
                <div className="form-group col-12 col-md-6">
                    <Field
                        name="LName"
                        component={renderField}
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Фамилия"
                    />
                </div>
                <div className="form-group col-12 col-md-6">
                    <Field
                        name="FName"
                        component={renderField}
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Имя"
                    />
                </div>
                <div className="form-group col-12 col-md-6">
                    <Field
                        name="MName"
                        component={renderField}
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Отчество"
                    />
                </div>
                <div className="form-group input-group col-12 col-md-6">
                    <div className="input-group-prepend">
                        <span className="input-group-text">+</span>
                    </div>
                    <Field
                        name="Phone"
                        component={renderField}
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Телефон"
                        normalize={normalizePhone}
                    />
                </div>
                <div className="col-12 modal-form__footer">
                    {renderAgree()}
                    <div className="form-group modal-form__submit-box">
                        <button
                            className="btn btn-lg btn-orange"
                            type="submit"
                            disabled={sending}
                        >
                            Войти
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const mapStetToProps = ({ formHelper }) => ({
    sending: formHelper.authForm.get('sending'),
});

export default connect(mapStetToProps)(
    reduxForm({ form: 'AuthForm', validate })(AuthForm),
);
