import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import classNames from 'classnames';

const validate = values => {
    const errors = {};

    if (!values.review) {
        errors.review = 'Required';
    }

    return errors;
};

const renderBtnBox = ({
    input,
    placeholder,
    type,
    className,
    meta: { touched, error },
}) => {
    return (
        <div className="order-review-modal__btn-box">
            <input
                {...input}
                type="radio"
                value="1"
                id="reviewRadio-bad"
            />
            <label
                htmlFor="reviewRadio-bad"
                className="reviewLabel-bad"
            />

            <input
                {...input}
                type="radio"
                value="2"
                id="reviewRadio-normal"
            />
            <label
                htmlFor="reviewRadio-normal"
                className="reviewLabel-normal"
            />

            <input
                {...input}
                type="radio"
                value="3"
                id="reviewRadio-ok"
            />
            <label
                htmlFor="reviewRadio-ok"
                className="reviewLabel-ok"
            />
        </div>
    );
};

const renderFields = mark => {
    if (!mark) return null;

    return (
        <div className="form-group col-12 col-lg-8 offset-lg-2">
            <Field
                name="comment"
                component="textarea"
                type="text"
                rows={2}
                className="form-control form-control-lg order-review-modal__textarea"
                placeholder="Добавьте комментарий..."
            />
        </div>
    );
};

const ReviewForm = ({ handleSubmit, sending, className, mark }) => {
    const containerClasses = classNames('modal-form__container', className);

    return (
        <div className={containerClasses}>
            <form
                onSubmit={handleSubmit}
                className="modal-form row"
            >
                <div className="form-group col-12">
                    <Field
                        name="mark"
                        component={renderBtnBox}
                        className="form-control form-control-lg"
                    />
                </div>
                {renderFields(mark)}
                <div className="col-12 modal-form__footer">
                    <div className="form-group modal-form__submit-box">
                        <button
                            className="btn btn-lg btn-orange"
                            type="submit"
                            disabled={!mark}
                        >
                            Отправить
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const selector = formValueSelector('ReviewForm');

const mapStateToProps = state => {
    return {
        mark: selector(state, 'mark'),
    };
};

export default connect(mapStateToProps)(
    reduxForm({ form: 'ReviewForm', validate })(ReviewForm),
);
