import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import Dotdotdot from 'react-dotdotdot';
import moment from '../../utils/moment';

const AdviceItem = ({ adviceItem, className }) => {
    return (
        <div className={`advice-item ${className}`}>
            <div className="advice-item__box">
                <div className="row no-gutters">
                    <div className="col-12 col-md-3 advice-item__img-box">
                        <Link
                            className="advice-item__img"
                            style={{
                                backgroundImage: `url(${adviceItem.get(
                                    'image_cover',
                                )})`,
                            }}
                            to={`/usefulinformation/${adviceItem.get('id')}`}
                        />
                    </div>
                    <div className="col-12 col-md-9">
                        <Link
                            className="advice-item__title"
                            to={`/usefulinformation/${adviceItem.get('id')}`}
                        >
                            <Dotdotdot clamp={2}>
                                {adviceItem.get('title')}
                            </Dotdotdot>
                        </Link>
                        <Dotdotdot
                            clamp={4}
                            className="advice-item__description"
                        >
                            {adviceItem.get('description')}
                        </Dotdotdot>
                        <div className="advice-item__date">
                            {moment(adviceItem.get('created_at')).format(
                                'D MMMM YYYY г.',
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AdviceItem.propTypes = {
    className: PropTypes.string,
    adviceItem: PropTypes.instanceOf(Map),
};

AdviceItem.defaultProps = {
    className: 'col-12',
    adviceItem: Map(),
};

export default AdviceItem;
