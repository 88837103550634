import React from 'react';
import { connect } from 'react-redux';
import FaqItem from './faqItem.js';
import FaqSkeleton from './faqSkeleton.js';
import FaqEmpty from './faqEmpty.js';

const AdvicesList = ({ faq, loading, className }) => {
    return (
        <div className={`faq-list ${className}`}>
            <div className="faq-list__box">
                <div className="row no-gutters">
                    {loading && !faq.size ? (
                        <FaqSkeleton />
                    ) : !faq.size ? (
                        <FaqEmpty />
                    ) : (
                        faq.map(faqItem => (
                            <FaqItem
                                faqItem={faqItem}
                                key={faqItem.get('id')}
                            />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

AdvicesList.defaultProps = {
    className: 'col-12',
};

const mapStateToProps = ({ faq }) => ({
    faq: faq.get('data'),
    loading: faq.get('loading'),
});

export default connect(mapStateToProps)(AdvicesList);
