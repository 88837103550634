import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
    sending: false,
    doneFirstStep: false,
    done: false,
    sendError: false,
    message: '',
    data: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_FORM__START_SENDING:
            return state
                .set('sendError', false)
                .set('done', false)
                .set('sending', true);

        case actionTypes.AUTH_FORM__DATA_RETRIEVED:
            return state.set('data', fromJS(action.payload));

        case actionTypes.AUTH_FORM__MESSAGE_RETRIEVED:
            return state.set('message', action.payload);

        case actionTypes.AUTH_FORM__END_FIRST_SENDING:
            return state.set('sending', false).set('doneFirstStep', true);

        case actionTypes.AUTH_FORM__END_SENDING:
            return state.set('sending', false).set('done', true);

        case actionTypes.AUTH_FORM__ERROR_SENDING:
            return state
                .set('sending', false)
                .set('done', true)
                .set('sendError', true);

        case actionTypes.AUTH_FORM__CLEAR:
            return fromJS(initialState);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
