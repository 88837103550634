import React from 'react';
import { connect } from 'react-redux';
import AdvicesItem from './advicesItem.js';
import AdvicesSkeleton from './advicesSkeleton.js';

const AdvicesList = ({ advices, loading, className }) => {
    return (
        <div className={`advices-list ${className}`}>
            <div className="row">
                {loading && !advices.size ? (
                    <AdvicesSkeleton className="col-12" />
                ) : (
                    advices.map(advice => (
                        <AdvicesItem
                            key={advice.get('id')}
                            adviceItem={advice}
                            className="col-12"
                        />
                    ))
                )}
            </div>
        </div>
    );
};

AdvicesList.defaultProps = {
    className: 'col-12',
};

const mapStateToProps = ({ advices }) => ({
    advices: advices.get('data'),
    loading: advices.get('loading'),
});

export default connect(mapStateToProps)(AdvicesList);
