import axios from '../utils/axios.js';
import { OK, NOT_FOUND } from '../constants/httpStatuses.js';
import { PAGINATION_LIMIT } from '../constants/pagination.js';
import actionTypes from './actionTypes.js';

export default page => async dispatch => {
    dispatch({ type: actionTypes.ADVICES__LOADING_START });

    const { data, status } = await axios({
        method: 'get',
        url: `api/advices/page/${page || 1}?limit=${PAGINATION_LIMIT}`,
    });

    if (status === OK) {
        dispatch({
            type: actionTypes.ADVICES__DATA_RETRIEVED,
            payload: data.advices,
        });

        dispatch({
            type: actionTypes.PAGINATION__RETRIEVED,
            payload: {
                limit: data.limit,
                page: data.page,
                total: data.total,
            },
        });
    }

    if (status === NOT_FOUND || data.advices.length === 0) {
        dispatch({
            type: actionTypes.HTTP_STATUS__SET,
            payload: NOT_FOUND,
        });
    }

    return dispatch({ type: actionTypes.ADVICES__LOADING_DONE });
};
