import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axiosIntegration from '../../utils/axiosIntegration.js';
import { integrationApiHost } from '../../config/index.js';
import { OK } from '../../constants/httpStatuses.js';

/**
 * Компонент который получает изображение в base64
 * и рендерит тег img
 */
const ImgFromBase = ({ imgUrl, imgName, className }) => {
    const [imgData, setImgData] = useState(null);

    useEffect(() => {
        if (imgUrl) {
            const imgUri = imgUrl.replace(integrationApiHost, '');

            (async function getData(url) {
                const { data, status } = await axiosIntegration({
                    method: 'get',
                    url,
                });

                if (status === OK && data.success === true) {
                    setImgData(data.data);
                }
            })(imgUri);
        }
    }, [imgUrl]);

    return (
        <img
            className={`img-from-base ${className}`}
            src={
                imgData
                    ? `data:image/png;base64, ${imgData}`
                    : '/static/icons/default-service.svg'
            }
            alt={imgName}
        />
    );
};

ImgFromBase.defaultProps = {
    className: '',
};

export default connect()(ImgFromBase);
