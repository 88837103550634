import axios from '../utils/axios.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default currentComplexId => async dispatch => {
    dispatch({ type: actionTypes.FAQ__LOADING_START });

    const { data, status } = await axios({
        method: 'get',
        url: `api/faq${
            currentComplexId ? `?housing_complexes_ids=${currentComplexId}` : ''
        }`,
    });

    if (status === OK) {
        dispatch({
            type: actionTypes.FAQ__DATA_RETRIEVED,
            payload: data.faq,
        });
    }

    return dispatch({ type: actionTypes.FAQ__LOADING_DONE });
};
