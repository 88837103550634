import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import CustomModal from '../customModal/customModal.js';
import ReviewForm from './reviewForm.js';
import markIncident from '../../actions/api.markIncident.js';

const ReviewBtn = ({ incidentId, dispatch }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const handleSubmit = values => {
        dispatch(markIncident({ incidentId, ...values }));
        toggle();
    };

    return (
        <Fragment>
            <button
                className="btn btn-orange-outline btn-sm"
                onClick={toggle}
            >
                Оцените заявку
            </button>
            <CustomModal
                className="order-review-modal"
                isOpen={isOpen}
                toggle={toggle}
                title="Оцените нашу работу"
                size="lg"
            >
                <ReviewForm
                    className="order-review-modal__body"
                    onSubmit={handleSubmit}
                />
            </CustomModal>
        </Fragment>
    );
};

export default connect()(ReviewBtn);
