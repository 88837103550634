import axios from '../utils/axios.js';
import { OK, NOT_FOUND } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default newsId => async dispatch => {
    dispatch({ type: actionTypes.NEWS_ITEM__LOADING_START });

    const { data, status } = await axios({
        method: 'get',
        url: `api/news/${newsId}`,
    });

    if (status === OK) {
        dispatch({
            type: actionTypes.NEWS_ITEM__DATA_RETRIEVED,
            payload: data.news,
        });
    }

    if (status === NOT_FOUND) {
        dispatch({
            type: actionTypes.HTTP_STATUS__SET,
            payload: NOT_FOUND,
        });
    }

    return dispatch({ type: actionTypes.NEWS_ITEM__LOADING_DONE });
};
