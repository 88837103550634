import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
    loading: false,
    isAuth: false,
    clientId: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH__START:
            return state.set('loading', true);

        case actionTypes.AUTH__SUCCESS:
            return state
                .set('clientId', action.payload.clientId)
                .set('isAuth', true)
                .set('loading', false);

        case actionTypes.AUTH__FAILED:
            return state
                .set('clientId', null)
                .set('isAuth', false)
                .set('loading', false);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
