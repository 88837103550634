import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import getInvoices from '../../actions/api.getInvoices.js';
import Spinner from '../spinner/spinner.js';
import InvoicesItem from './invoicesItem.js';

const InvoiceList = ({
    dispatch,
    className,
    personalAccountNumber,
    invoices,
    allUploaded,
    loading,
}) => {
    const elementClasses = classNames('invoices-list', className);

    const loadFunc = page => {
        dispatch(getInvoices(personalAccountNumber, page));
    };

    const scrollLoader = () => {
        if (!invoices.size) return null;
        return (
            <Spinner
                width={50}
                key="scrollLoader"
            />
        );
    };

    if (loading) {
        return (
            <div className={elementClasses}>
                <Spinner />
            </div>
        );
    }

    const renderBox = () => {
        if (!invoices.size) {
            return (
                <div className="invoices-list__empty">
                    <div className="invoices-list__empty-icon" />
                    <div className="invoices-list__empty-text">
                        Платежи еще не были произведены
                    </div>
                </div>
            );
        }

        return (
            <InfiniteScroll
                pageStart={1}
                loadMore={loadFunc}
                hasMore={!allUploaded}
                initialLoad={false}
                loader={scrollLoader()}
            >
                <div className="row">
                    {invoices.map(invoice => {
                        return (
                            <InvoicesItem
                                key={invoice.get('id')}
                                className="col-12"
                                invoice={invoice}
                            />
                        );
                    })}
                </div>
            </InfiniteScroll>
        );
    };

    return (
        <div className={elementClasses}>
            <div className="invoices-list__box">{renderBox()}</div>
        </div>
    );
};

InvoiceList.propTypes = {
    className: PropTypes.string,
    personalAccountNumber: PropTypes.string,
};

InvoiceList.defaultProps = {
    className: 'col-12',
};

const mapStateToProps = ({ invoices }) => ({
    loading: invoices.get('loading'),
    invoices: invoices.get('data'),
    allUploaded: invoices.get('allUploaded'),
});

export default connect(mapStateToProps)(InvoiceList);
