import React from 'react';
import { ClipLoader } from 'react-spinners';

const Spinner = ({ width, className }) => {
    return (
        <div className={`spinner-box ${className}`}>
            <ClipLoader
                size={width}
                color="#C7C7CC"
            />
        </div>
    );
};

Spinner.defaultProps = {
    width: 100,
    className: '',
};

export default Spinner;
