import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ImgFromBase from '../imgFromBase/imgFromBase.js';

const ServicesGroupItem = ({ className, serviceGroup }) => {
    const elementClasses = classNames('services-group-item', className);

    return (
        <div className={elementClasses}>
            <div className="services-group-item__box">
                <div className="services-group-item__img-box">
                    <ImgFromBase
                        imgUrl={serviceGroup.get('IconRef')}
                        imgName={serviceGroup.get('IconFileName')}
                        className="services-group-item__img"
                    />
                </div>
                <div className="services-group-item__title regular-font">
                    {serviceGroup.get('Name')}
                </div>
                <Link
                    to={`/services/${serviceGroup.get('Id')}`}
                    className="services-group-item__link regular-font"
                >
                    Выбрать
                </Link>
            </div>
        </div>
    );
};

ServicesGroupItem.defaultProps = {
    className: 'col-12',
};

export default ServicesGroupItem;
