import actionTypes from './actionTypes.js';

export const updatePage = pageNumber => {
    return {
        type: actionTypes.PAGINATION__UPDATE_PAGE,
        payload: pageNumber,
    };
};

export const clearPagination = () => {
    return { type: actionTypes.PAGINATION__CLEAR };
};
