import React from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';
import OrderItem from './orderItem.js';
import Spinner from '../spinner/spinner.js';
import getIncidents from '../../actions/api.getIncidents.js';
import isTable from '../../utils/isTable.js';

const CurrentOrdersList = ({
    className,
    loading,
    allUploaded,
    orders,
    dispatch,
}) => {
    const elementClasses = classNames('orders-list', className);

    const loadFunc = page => {
        dispatch(getIncidents({ page, isActive: true }));
    };

    const scrollLoader = () => {
        if (!orders.size) return null;
        return (
            <Spinner
                width={50}
                key="scrollLoader"
            />
        );
    };

    if (loading) {
        return (
            <div className={elementClasses}>
                <Spinner />
            </div>
        );
    }

    return (
        <div className={elementClasses}>
            {isTable() ? null : (
                <div className="orders__box-title">В обработке</div>
            )}
            <InfiniteScroll
                pageStart={1}
                loadMore={loadFunc}
                hasMore={!allUploaded}
                initialLoad={false}
                loader={scrollLoader()}
            >
                <div className="row">
                    {orders.map(order => (
                        <OrderItem
                            key={order.get('Id')}
                            order={order}
                        />
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );
};

CurrentOrdersList.defaultProps = {
    className: 'col-12',
};

const mapStateToProps = ({ orders }) => ({
    loading: orders.active.get('loading'),
    allUploaded: orders.active.get('allUploaded'),
    orders: orders.active.get('data'),
});

export default connect(mapStateToProps)(CurrentOrdersList);
