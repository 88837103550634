import React from 'react';
import { List } from 'immutable';
import ImgFromBase from '../imgFromBase/imgFromBase.js';
import PerformersItem from './performersItem.js';

const PerformersList = ({ serviceGroup, service, projectId }) => {
    const filterPerformers = () => {
        const performers = service.get('performers');
        if (!performers) return List();

        return performers.reduce((accum, performer) => {
            if (
                !performer.get('ProjectId') ||
                performer.get('ProjectId') === projectId
            ) {
                return accum.push(performer);
            }
            return accum;
        }, List());
    };

    return (
        <div className="service-list col-12">
            <div className="service-list__box">
                <div className="service-list__header">
                    <div className="service-list__img-box">
                        <ImgFromBase
                            imgUrl={serviceGroup.get('IconRef')}
                            imgName={serviceGroup.get('IconFileName')}
                            className="service-list__img"
                        />
                    </div>
                    <p className="service-list__title regular-font">
                        {serviceGroup.get('Name')}
                        &nbsp;-&nbsp;
                        {service.get('Name')}
                    </p>
                </div>
                <div className="service-list__performers row">
                    {filterPerformers().map(item => (
                        <PerformersItem
                            key={item.get('Id')}
                            performerObj={item}
                            serviceGroupId={serviceGroup.get('Id')}
                            serviceId={service.get('Id')}
                            className="col-12 col-md-6 col-lg-4"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PerformersList;
