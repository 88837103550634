import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { NOT_FOUND } from './constants/httpStatuses.js';
import HomePage from './pages/homePage.js';
import NewsPage from './pages/newsPage.js';
import NewsItemPage from './pages/newsItemPage.js';
import AdvicesPage from './pages/advicesPage.js';
import AdvicePage from './pages/advicePage.js';
import ContactsPage from './pages/contactsPage.js';
import PrivacyPolicyPage from './pages/privacyPolicyPage.js';
import FaqPage from './pages/faqPage.js';
import ProfilePage from './pages/profilePage.js';
import InvoicesPage from './pages/invoicesPage.js';
import CounterHistoryPage from './pages/counterHistoryPage.js';
import CounterChartPage from './pages/counterChartPage.js';
import ServicesGroupPage from './pages/servicesGroupPage.js';
import ServicesPage from './pages/servicesPage.js';
import PerformerPage from './pages/performerPage.js';
import OrdersPage from './pages/ordersPage.js';
import SubscriptionPage from './pages/subscriptionPage.js';
import NotFoundPage from './pages/notFoundPage.js';
import TermsOfUsePage from './pages/termsOfUsePage.js';
import CookiesPolicyPage from './pages/cookiesPolicyPage.js';

const Routes = ({ history, httpStatus, isAuth }) => {
    const routing = () => {
        const defaultRouts = [
            { path: '/', component: HomePage },
            { path: '/news', component: NewsPage },
            { path: '/news/:id', component: NewsItemPage },
            { path: '/usefulinformation', component: AdvicesPage },
            { path: '/usefulinformation/:id', component: AdvicePage },
            { path: '/contacts', component: ContactsPage },
            { path: '/privacy-policy', component: PrivacyPolicyPage },
            { path: '/faq', component: FaqPage },
            { path: '/subscription', component: SubscriptionPage },
            { path: '/terms-of-use', component: TermsOfUsePage },
            { path: '/cookies-policy', component: CookiesPolicyPage },
        ];

        const authRouts = [
            { path: '/profile', component: ProfilePage },
            { path: '/profile/invoices', component: InvoicesPage },
            { path: '/profile/history', component: CounterHistoryPage },
            { path: '/profile/history/chart', component: CounterChartPage },
            { path: '/profile/orders', component: OrdersPage },
            { path: '/services', component: ServicesGroupPage },
            { path: '/services/:id', component: ServicesPage },
            { path: '/services/:id/item/:serviceId', component: PerformerPage },
        ];

        const routs = isAuth ? defaultRouts.concat(authRouts) : defaultRouts;

        return routs.map((rout, idx) => (
            <Route
                exact
                path={rout.path}
                component={rout.component}
                key={idx}
            />
        ));
    };

    if (httpStatus === NOT_FOUND) {
        return (
            <ConnectedRouter history={history}>
                <NotFoundPage />
            </ConnectedRouter>
        );
    }

    return (
        <ConnectedRouter history={history}>
            <Switch>
                {routing()}
                <Route component={NotFoundPage} />
            </Switch>
        </ConnectedRouter>
    );
};

const mapStateToProps = ({ global }) => ({
    httpStatus: global.httpStatus,
    isAuth: global.auth.get('isAuth'),
});

export default connect(mapStateToProps)(Routes);
