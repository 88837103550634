import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
    cookiesAccepted: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COOKIES__ACCEPT:
            return state.set('cookiesAccepted', true);

        case actionTypes.COOKIES__CHECK:
            return state.set('cookiesAccepted', action.payload);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
