import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default email => async (dispatch, getState) => {
    dispatch({ type: actionTypes.EDIT_EMAIL_FORM__START_SENDING });

    const ClientID = getState().global.auth.get('clientId');

    const { data, status } = await axiosIntegration({
        method: 'post',
        url: `api/v1/uk/ChangeEmail`,
        data: { Email: email, ClientID },
    });

    if (status === OK && data.success === true) {
        dispatch({
            type: actionTypes.CLIENT_DATA__CHOOSE_EMAIL,
            payload: { email: data.data.Email },
        });
        return dispatch({ type: actionTypes.EDIT_EMAIL_FORM__END_SENDING });
    }

    if (status === OK && data.success === false) {
        dispatch({
            type: actionTypes.EDIT_EMAIL_FORM__MESSAGE_RETRIEVED,
            payload: data.message,
        });
        return dispatch({ type: actionTypes.EDIT_EMAIL_FORM__ERROR_SENDING });
    }

    return dispatch({ type: actionTypes.EDIT_EMAIL_FORM__ERROR_SENDING });
};
