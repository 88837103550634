import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from '../../utils/moment.js';
import AdviceDocuments from './adviceDocuments.js';

const AdviceItem = ({
    title,
    article,
    description,
    imageCover,
    documents,
    createdAt,
    className,
}) => {
    const elementClasses = classNames('item-box', className);

    return (
        <div className={elementClasses}>
            <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                    <img
                        className="img-thumbnail"
                        src={imageCover}
                        alt={title}
                    />
                    <h1 className="item-box__title">{title}</h1>
                    <div className="item-box__date">
                        {moment(createdAt).format('D MMMM YYYY г.')}
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                    <div className="item-box__desc">{description}</div>
                    <div
                        className="item-box__article"
                        dangerouslySetInnerHTML={{ __html: article }}
                    />
                    {documents && documents.size ? (
                        <AdviceDocuments documents={documents} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

AdviceItem.defaultProps = {
    className: 'col-12',
};

const mapStetToProps = ({ advice }) => ({
    title: advice.getIn(['data', 'title']),
    article: advice.getIn(['data', 'article']),
    description: advice.getIn(['data', 'description']),
    imageCover: advice.getIn(['data', 'image_cover']),
    documents: advice.getIn(['data', 'documents']),
    createdAt: advice.getIn(['data', 'created_at']),
});

export default connect(mapStetToProps)(AdviceItem);
