import React from 'react';
import SkeletonLine from '../skeletonLine/skeletonLine.js';

const ProfileCounterSkeleton = () => {
    return (
        <div className="profile-counter-skeleton__box">
            <SkeletonLine className="profile-counter-skeleton__title" />
            <div className="profile-counter__delimiter regular-font">
                Ручные показатели
            </div>
            <SkeletonLine className="profile-counter-skeleton__line" />
            <SkeletonLine className="profile-counter-skeleton__line" />
            <div className="profile-counter__delimiter regular-font">
                Автоматические показатели
            </div>
            <SkeletonLine className="profile-counter-skeleton__line" />
            <SkeletonLine className="profile-counter-skeleton__line" />
        </div>
    );
};

export default ProfileCounterSkeleton;
