import React, { Fragment } from 'react';
import SkeletonLine from '../skeletonLine/skeletonLine.js';

const ProfileCounterSkeleton = () => {
    return (
        <Fragment>
            <div className="profile-invoice__box">
                <SkeletonLine className="profile-invoice-skeleton__title" />
                <SkeletonLine className="profile-invoice-skeleton__line" />
                <SkeletonLine className="profile-invoice-skeleton__line" />
                <SkeletonLine className="profile-invoice-skeleton__line" />
                <SkeletonLine className="profile-invoice-skeleton__line-big" />
            </div>
            <div className="profile-invoice__delimiter-box">
                <div className="profile-invoice__delimiter" />
            </div>
            <div className="profile-invoice__pay-box">
                <SkeletonLine className="profile-invoice-skeleton__pay" />
            </div>
        </Fragment>
    );
};

export default ProfileCounterSkeleton;
