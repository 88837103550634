import axios from '../utils/axios.js';
import actionTypes from './actionTypes.js';

export default formData => async dispatch => {
    dispatch({ type: actionTypes.FILE_STORAGE__LOADING_START });
    const cmsToken = localStorage.getItem('cmsToken');
    const { data, status } = await axios({
        method: 'post',
        url: `api/storage/image/request`,
        data: formData,
        headers: {
            Authorization: `Bearer ${cmsToken}`,
        },
    });

    if (status === 201) {
        dispatch({
            type: actionTypes.FILE_STORAGE__ADD_FILE,
            payload: data.storage,
        });
    }

    return dispatch({ type: actionTypes.FILE_STORAGE__LOADING_DONE });
};
