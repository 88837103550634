import React, { useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ProfileInvoice from '../profileInvoice/profileInvoice.js';
import ProfileCounter from '../profileCounter/profileCounter.js';
import EditEmail from '../editEmail/editEmail.js';
import MobileSwitch from '../mobileSwitch/mobileSwitch';
import CurrentOrdersList from '../orders/currentOrdersList';
import HistoryOrderList from '../orders/historyOrderList';

const ProfileInfo = ({
    className,
    lastName,
    firstName,
    middleName,
    email,
    mobilePhone,
    accountNumber,
}) => {
    const elementClasses = classNames('profile-info', className);
    const accountNumberRef = useRef(null);

    const handleCopyClick = () => {
        const el = document.createElement('textarea');
        el.value = accountNumberRef.current.innerText;
        el.style.position = 'absolute';
        el.style.buttong = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const renderFirstComponent = () => (
        <ProfileCounter className="col-12 col-lg-6" />
    );
    const renderSecondComponent = () => (
        <ProfileInvoice className="col-12 col-lg-6" />
    );

    return (
        <div className={elementClasses}>
            <div className="row no-gutters">
                <div className="col-12 col-md-4 col-lg-3 profile-info__sidebar">
                    <div className="profile-info__box">
                        <div className="profile-info__box-title">
                            Информация
                        </div>
                        <dl className="row">
                            <dt className="col-12">Фамилия</dt>
                            <dd className="col-12">{lastName}</dd>
                            <dt className="col-12">Имя</dt>
                            <dd className="col-12">{firstName}</dd>
                            <dt className="col-12">Отчество</dt>
                            <dd className="col-12">{middleName}</dd>
                        </dl>
                        <div className="profile-info__box-subtitle">
                            Контакты
                        </div>
                        <dl className="row">
                            <dt className="col-12">Телефон</dt>
                            <dd className="col-12">+{mobilePhone}</dd>
                            <dt className="col-12">Email</dt>
                            <dd className="col-12 profile-info__email-box">
                                <span>{email}</span>
                                <EditEmail email={email} />
                            </dd>
                        </dl>
                        <div className="profile-info__bill-title">
                            Номер лицевого счета
                        </div>
                        <div className="profile-info__bill-box">
                            <span ref={accountNumberRef}>{accountNumber}</span>
                            <span
                                className="profile-info__bill-copy"
                                onClick={handleCopyClick}
                            >
                                Скопировать
                            </span>
                        </div>
                    </div>
                    <div className="profile-info__box">
                        <p>
                            Оформив заявку вы сможете написать обращение,
                            заказать услугу или сообщить о проблеме
                        </p>

                        <Link
                            className="profile-info__link-to-order"
                            to="/profile/orders"
                        >
                            Оформить зяавку
                        </Link>
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                    <div className="profile-info__box">
                        <div className="row">
                            <MobileSwitch
                                renderFirstComponent={renderFirstComponent}
                                renderSecondComponent={renderSecondComponent}
                                firstLabel="Показания счетчиков"
                                secondLabel="Платежи"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ clientData }) => ({
    lastName: clientData.get('lastName'),
    firstName: clientData.get('firstName'),
    middleName: clientData.get('middleName'),
    email: clientData.get('email'),
    mobilePhone: clientData.get('mobilePhone'),
    accountNumber: clientData.getIn([
        'currentDependency',
        'PersonalAccount',
        'Number',
    ]),
});

export default connect(mapStateToProps)(ProfileInfo);
