import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default ({ incidentId, comment, mark }) =>
    async (dispatch, getState) => {
        const { data, status } = await axiosIntegration({
            method: 'post',
            url: '/api/v1/uk/MPMarkIncident',
            data: {
                IncidentId: incidentId,
                Comment: comment,
                Mark: mark,
            },
        });

        if (status === OK && data.success === true) {
            dispatch({
                type: actionTypes.ORDERS_HISTORY__MARK,
                payload: data.data,
            });
        }
    };
