import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import {
    updateStartDate,
    updateEndDate,
} from '../../actions/action.counter.js';
import moment from '../../utils/moment.js';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ru', ru);

class CustomInput extends PureComponent {
    render() {
        const { onClick, value, isEnd } = this.props;

        return (
            <button
                className="history-picker__btn"
                onClick={onClick}
            >
                <span>{isEnd ? 'заканчивая' : 'начиная'}</span>
                {value}
            </button>
        );
    }
}

const HistoryChartPicker = ({ dispatch, startDate, endDate }) => {
    const changeStartDate = date => {
        dispatch(updateStartDate(moment(date).toDate()));
    };

    const changeEndDate = date => {
        dispatch(updateEndDate(moment(date).toDate()));
    };

    return (
        <div className="history-picker">
            <DatePicker
                selected={startDate || undefined}
                onChange={changeStartDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                locale="ru"
                dateFormat="MM.yyyy"
                showMonthYearPicker
                customInput={<CustomInput />}
            />
            <DatePicker
                selected={endDate || undefined}
                onChange={changeEndDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                locale="ru"
                dateFormat="MM.yyyy"
                showMonthYearPicker
                customInput={<CustomInput isEnd />}
            />
        </div>
    );
};

const mapStateToProps = ({ counter }) => ({
    startDate: counter.get('startDate'),
    endDate: counter.get('endDate'),
});

export default connect(mapStateToProps)(HistoryChartPicker);
