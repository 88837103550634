import axiosIntegration from '../utils/axiosIntegration.js';
import { SCROLL_LOAD_LIMIT } from '../constants/pagination.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default (personalAccountNumber, page) => async (dispatch, getState) => {
    if (!personalAccountNumber) return null;
    const clientID = getState().global.auth.get('clientId');

    if (!page) dispatch({ type: actionTypes.COUNTER__LOADING_START });

    const { data, status } = await axiosIntegration({
        method: 'post',
        url: 'api/v1/uk/counters/history',
        data: {
            clientID,
            personalAccountNumber,
            page: page || 1,
            countOnPage: SCROLL_LOAD_LIMIT,
        },
    });

    if (status === OK && data.success === true) {
        dispatch({
            type: actionTypes.COUNTER__HISTORY_MERGE,
            payload: data.data,
        });
    }

    return dispatch({ type: actionTypes.COUNTER__LOADING_DONE });
};
