import React from 'react';
import mobileAppLinks from '../../config/mobileAppLinks.js';

const MobileAppLinks = ({ withQr }) => {
    return (
        <div className="mobile-app-links">
            <div className="mobile-app-links__box">
                {withQr ? (
                    <img
                        src="/static/images/qrPlayMarket.gif"
                        alt="Google Play Qr"
                        className="mobile-app-links__qr"
                    />
                ) : null}
                <a
                    href={mobileAppLinks.playMarketUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/static/images/google-play.png"
                        alt="Скачать ProHome из Google Play"
                    />
                </a>
            </div>

            <div className="mobile-app-links__box">
                {withQr ? (
                    <img
                        src="/static/images/qrAppStore.jpg"
                        alt="App Store Qr"
                        className="mobile-app-links__qr"
                    />
                ) : null}
                <a
                    href={mobileAppLinks.appStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/static/images/app-store.png"
                        alt="Скачать ProHome из App Store"
                    />
                </a>
            </div>
        </div>
    );
};

MobileAppLinks.defaultProps = {
    withQr: false,
};

export default MobileAppLinks;
