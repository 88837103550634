import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import CustomModal from '../customModal/customModal.js';
import { clearEditEmailFrom } from '../../actions/action.formHelpers.js';
import editEmail from '../../actions/api.editEmail.js';
import EditEmailForm from './editEmailForm.js';

const EditEmail = ({ dispatch, email, done, sending, message, sendError }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        dispatch(clearEditEmailFrom());
        setIsOpen(!isOpen);
    };

    const handleSubmit = values => {
        dispatch(editEmail(values.email));
    };

    const renderContent = () => {
        if (done && !sendError) {
            return (
                <div className="modal-form__container">
                    <div className="modal-form__done-box">
                        <div className="modal-form__ok-icon" />
                        <div className="modal-form__done-title">
                            Email изменен
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <EditEmailForm
                onSubmit={handleSubmit}
                email={email}
                sending={sending}
                errMessage={sendError ? message : ''}
            />
        );
    };

    return (
        <Fragment>
            <span
                className="profile-info__email-copy"
                onClick={toggle}
            >
                Изменить
            </span>
            <CustomModal
                className="profile-info__email-modal"
                isOpen={isOpen}
                toggle={toggle}
                title="Изменить email"
                size="lg"
            >
                {renderContent()}
            </CustomModal>
        </Fragment>
    );
};

const mapStetToProps = ({ formHelper }) => ({
    done: formHelper.editEmailForm.get('done'),
    message: formHelper.editEmailForm.get('message'),
    sendError: formHelper.editEmailForm.get('sendError'),
    sending: formHelper.editEmailForm.get('sending'),
});

export default connect(mapStetToProps)(EditEmail);
