import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
    loading: false,
    done: false,
    serviceGroups: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SERVICES__DATA_RETRIEVED:
            const usersProjectsIds = action.payload.usersProjects.map(
                usersProject => usersProject.get('Id'),
            );

            return state.set('done', true).set(
                'serviceGroups',
                action.payload.serviceGroups.reduce((accum, serviceGroup) => {
                    const services = serviceGroup.Services.reduce(
                        (accum, service) => {
                            const performers =
                                service.PerformersRelations.reduce(
                                    (accum, performer) => {
                                        if (!performer.Id) return accum;

                                        if (!performer.ProjectId) {
                                            return accum
                                                .set(
                                                    'projectsIds',
                                                    usersProjectsIds,
                                                )
                                                .set(
                                                    'data',
                                                    accum
                                                        .get('data')
                                                        .push(
                                                            fromJS(performer),
                                                        ),
                                                );
                                        }

                                        if (
                                            usersProjectsIds.find(
                                                usersProjectsId =>
                                                    usersProjectsId ===
                                                    performer.ProjectId,
                                            )
                                        ) {
                                            return accum
                                                .set(
                                                    'data',
                                                    accum
                                                        .get('data')
                                                        .push(
                                                            fromJS(performer),
                                                        ),
                                                )
                                                .set(
                                                    'projectsIds',
                                                    accum
                                                        .get('projectsIds')
                                                        .find(
                                                            projectsId =>
                                                                projectsId ===
                                                                performer.ProjectId,
                                                        )
                                                        ? accum.get(
                                                              'projectsIds',
                                                          )
                                                        : accum
                                                              .get(
                                                                  'projectsIds',
                                                              )
                                                              .push(
                                                                  performer.ProjectId,
                                                              ),
                                                );
                                        }

                                        return accum;
                                    },
                                    fromJS({ projectsIds: [], data: [] }),
                                );

                            if (!performers.get('projectsIds').size)
                                return accum;

                            const imService = fromJS(service)
                                .set(
                                    'projectsIds',
                                    performers.get('projectsIds'),
                                )
                                .set('performers', performers.get('data'))
                                .remove('ProjectIds')
                                .remove('PerformersRelations');

                            return accum
                                .set('data', accum.get('data').push(imService))
                                .set(
                                    'projectsIds',
                                    performers
                                        .get('projectsIds')
                                        .reduce((accumulator, projectsId) => {
                                            if (
                                                accumulator.find(
                                                    id => id === projectsId,
                                                )
                                            )
                                                return accumulator;
                                            return accumulator.push(projectsId);
                                        }, accum.get('projectsIds')),
                                );
                        },
                        fromJS({ projectsIds: [], data: [] }),
                    );

                    if (!services.get('projectsIds').size) return accum;

                    const imServiceGroup = fromJS(serviceGroup)
                        .set('projectsIds', services.get('projectsIds'))
                        .set('services', services.get('data'))
                        .remove('Services');

                    return accum.push(imServiceGroup);
                }, fromJS([])),
            );

        case actionTypes.SERVICES__LOADING_START:
            return state.set('loading', true);

        case actionTypes.SERVICES__LOADING_DONE:
            return state.set('loading', false);

        case actionTypes.SERVICES__CLEAR:
            return fromJS(initialState);

        default:
            return isImmutable(state) ? state : fromJS(state);
    }
};
