import axios from 'axios';
import cookie from 'js-cookie';
import { initStoreAndHistory } from '../store.js';
import removeToken from '../actions/action.removeToken.js';
import { UNAUTHORIZED } from '../constants/httpStatuses.js';
import { integrationApiHost } from '../config/index.js';

const instance = axios.create({
    baseURL: integrationApiHost,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    validateStatus: function (status) {
        return status >= 200 && status < 500; // возможно понадобится более жесткое условие
    },
});

instance.interceptors.response.use(res => {
    if (res.status === UNAUTHORIZED) {
        const { store } = initStoreAndHistory({});
        store.dispatch(removeToken());
    }

    return res;
});

export default async ({ method = 'get', data = {}, url, headers = {} }) => {
    const Authorization = cookie.get('AccessToken');

    return await instance({
        url,
        method,
        data,
        headers: {
            ...headers,
            Authorization: `Bearer ${Authorization || ''}`,
        },
    });
};
