import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default PersonalAccountNumber => async (dispatch, getState) => {
    if (!PersonalAccountNumber) return null;

    dispatch({ type: actionTypes.INVOICES__LOADING_START });

    const ClientID = getState().global.auth.get('clientId');

    const { data, status } = await axiosIntegration({
        method: 'post',
        url: 'api/v1/uk/Invoice',
        data: {
            ClientID,
            PersonalAccountNumber,
            Archive: false,
            Page: 1,
            CountOnPage: 1,
        },
    });

    if (status === OK && data.success === true) {
        dispatch({
            type: actionTypes.INVOICES__CURRENT_RETRIEVED,
            payload: data.data[0],
        });
    }

    return dispatch({ type: actionTypes.INVOICES__LOADING_DONE });
};
