import qs from 'query-string';
import { set } from 'lodash';
import axios from '../utils/axios.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.NEWS__LOADING_START });

    const currentComplexId = getState().global.complexes.get('current');

    const options = {
        published_to: 'site',
        limit: 9,
    };

    if (currentComplexId) {
        set(options, 'housing_complexes_ids', currentComplexId);
    }

    const query = qs.stringify(options);

    const { data, status } = await axios({
        method: 'get',
        url: `api/v2/news/page/1?${query}`,
    });

    if (status === OK) {
        dispatch({
            type: actionTypes.NEWS__DATA_RETRIEVED,
            payload: data.news,
        });
        dispatch({
            type: actionTypes.PAGINATION__RETRIEVED,
            payload: {
                limit: data.limit,
                page: data.page,
                total: data.total,
            },
        });
    }

    return dispatch({ type: actionTypes.NEWS__LOADING_DONE });
};
