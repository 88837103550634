import React from 'react';
import Helmet from 'react-helmet';
import ErrorLayouts from '../layouts/errorLayouts.js';
import Header from '../components/header/header.js';
import mobileAppLinks from '../config/mobileAppLinks.js';

const SubscriptionPage = () => {
    return (
        <ErrorLayouts className="subscription-page">
            <Helmet>
                <title>Благодарим за подписку! - PSN Group</title>
            </Helmet>
            <Header className="col-12">
                <h2>Благодарим за подписку!</h2>
            </Header>
            <div className="col-12 subscription-page__text-box">
                <p style={{ marginBottom: '3rem' }}>
                    Мы будем держать Вас в курсе событий!
                </p>
                <p>С заботой о Вас,</p>
                <p>команда ProHome</p>
            </div>
            <div className="col-12 subscription-page__link_box">
                <a
                    href={mobileAppLinks.playMarketUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/static/images/google-play.png"
                        alt="Скачать PSN Home из Google Play"
                    />
                </a>
                <a
                    href={mobileAppLinks.appStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/static/images/app-store.png"
                        alt="Скачать PSN Home из App Store"
                    />
                </a>
            </div>
        </ErrorLayouts>
    );
};

export default SubscriptionPage;
