import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import DefaultLayout from '../layouts/defaultLayouts.js';
import Header from '../components/header/header.js';
import getClientData from '../actions/api.getClientData.js';
import DependencySelection from '../components/dependencySelection/dependencySelection.js';
import ProfileInfo from '../components/profileInfo/profileInfo.js';
import Spinner from '../components/spinner/spinner.js';

const NewsPage = ({ dispatch, loading }) => {
    useEffect(() => {
        dispatch(getClientData());
    }, []);

    const renderContent = () => {
        if (loading) {
            return (
                <div className="col-12">
                    <Spinner />
                </div>
            );
        }

        return (
            <Fragment>
                <DependencySelection className="col-12" />
                <ProfileInfo className="col-12" />
            </Fragment>
        );
    };

    return (
        <DefaultLayout>
            <Helmet>
                <title>Личный кабинет - ProHome</title>
                <meta
                    name="description"
                    content="Личный кабинет"
                />
            </Helmet>
            <Header className="col-12">
                <h2>Личный кабинет</h2>
            </Header>
            {renderContent()}
        </DefaultLayout>
    );
};

const mapStateToProps = ({ clientData }) => ({
    loading: clientData.get('loading'),
});

export default connect(mapStateToProps)(NewsPage);
