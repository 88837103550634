const actionTypes = {
    HTTP_STATUS__SET: 'HTTP_STATUS__SET',

    AUTH__START: 'AUTH__START',
    AUTH__SUCCESS: 'AUTH__SUCCESS',
    AUTH__FAILED: 'AUTH__FAILED',

    PAGINATION__RETRIEVED: 'PAGINATION__RETRIEVED',
    PAGINATION__UPDATE_PAGE: 'PAGINATION__UPDATE_PAGE',
    PAGINATION__CLEAR: 'PAGINATION__CLEAR',

    COMPLEXES__INIT: 'COMPLEXES__INIT',
    COMPLEXES__CHOOSE_CURRENT: 'COMPLEXES__CHOOSE_CURRENT',

    NEWS__DATA_RETRIEVED: 'NEWS__DATA_RETRIEVED',
    NEWS__LOADING_START: 'NEWS__LOADING_START',
    NEWS__LOADING_DONE: 'NEWS__LOADING_DONE',
    NEWS__CLEAR: 'NEWS__CLEAR',

    NEWS_ITEM__DATA_RETRIEVED: 'NEWS_ITEM__DATA_RETRIEVED',
    NEWS_ITEM__LOADING_START: 'NEWS_ITEM__LOADING_START',
    NEWS_ITEM__LOADING_DONE: 'NEWS_ITEM__LOADING_DONE',
    NEWS_ITEM__CLEAR: 'NEWS_ITEM__CLEAR',

    ADVICES__DATA_RETRIEVED: 'ADVICES__DATA_RETRIEVED',
    ADVICES__LOADING_START: 'ADVICES__LOADING_START',
    ADVICES__LOADING_DONE: 'ADVICES__LOADING_DONE',

    ADVICE__DATA_RETRIEVED: 'ADVICE__DATA_RETRIEVED',
    ADVICE__LOADING_START: 'ADVICE__LOADING_START',
    ADVICE__LOADING_DONE: 'ADVICE__LOADING_DONE',
    ADVICE__CLEAR: 'ADVICE__CLEAR',

    ADMINISTRATIVE_OBJECTS__OFFICE_RETRIEVED:
        'ADMINISTRATIVE_OBJECTS__OFFICE_RETRIEVED',
    ADMINISTRATIVE_OBJECTS__LOADING_START:
        'ADMINISTRATIVE_OBJECTS__LOADING_START',
    ADMINISTRATIVE_OBJECTS__LOADING_DONE:
        'ADMINISTRATIVE_OBJECTS__LOADING_DONE',
    ADMINISTRATIVE_OBJECTS__CLEAR: 'ADMINISTRATIVE_OBJECTS__CLEAR',

    FAQ__DATA_RETRIEVED: 'FAQ__DATA_RETRIEVED',
    FAQ__LOADING_START: 'FAQ__LOADING_START',
    FAQ__LOADING_DONE: 'FAQ__LOADING_DONE',

    REQUEST_CALL__START_SENDING: 'REQUEST_CALL__START_SENDING',
    REQUEST_CALL__END_SENDING: 'REQUEST_CALL__END_SENDING',
    REQUEST_CALL__ERROR_SENDING: 'REQUEST_CALL__ERROR_SENDING',
    REQUEST_CALL__CLEAR: 'REQUEST_CALL__CLEAR',

    PERFORMER_ORDER__START_SENDING: 'PERFORMER_ORDER__START_SENDING',
    PERFORMER_ORDER__END_SENDING: 'PERFORMER_ORDER__END_SENDING',
    PERFORMER_ORDER__ERROR_SENDING: 'PERFORMER_ORDER__ERROR_SENDING',
    PERFORMER_ORDER__CLEAR: 'PERFORMER_ORDER__CLEAR',

    AUTH_FORM__START_SENDING: 'AUTH_FORM__START_SENDING',
    AUTH_FORM__DATA_RETRIEVED: 'AUTH_FORM__DATA_RETRIEVED',
    AUTH_FORM__MESSAGE_RETRIEVED: 'AUTH_FORM__MESSAGE_RETRIEVED',
    AUTH_FORM__END_SENDING: 'AUTH_FORM__END_SENDING',
    AUTH_FORM__END_FIRST_SENDING: 'AUTH_FORM__END_FIRST_SENDING',
    AUTH_FORM__ERROR_SENDING: 'AUTH_FORM__ERROR_SENDING',
    AUTH_FORM__CLEAR: 'AUTH_FORM__CLEAR',

    CLIENT_DATA__DATA_RETRIEVED: 'CLIENT_DATA__DATA_RETRIEVED',
    CLIENT_DATA__CHOOSE_EMAIL: 'CLIENT_DATA__CHOOSE_EMAIL',
    CLIENT_DATA__LOADING_START: 'CLIENT_DATA__LOADING_START',
    CLIENT_DATA__LOADING_DONE: 'CLIENT_DATA__LOADING_DONE',
    CLIENT_DATA__CHOOSE_DEPENDENCY: 'CLIENT_DATA__CHOOSE_DEPENDENCY',
    CLIENT_DATA__CLEAR: 'CLIENT_DATA__CLEAR',

    INVOICES__CURRENT_RETRIEVED: 'INVOICES__CURRENT_RETRIEVED',
    INVOICES__LOADING_START: 'INVOICES__LOADING_START',
    INVOICES__LOADING_DONE: 'INVOICES__LOADING_DONE',
    INVOICES__PAY_START: 'INVOICES__PAY_START',
    INVOICES__PAY_DONE: 'INVOICES__PAY_DONE',
    INVOICES__DATA_RETRIEVED: 'INVOICES__DATA_RETRIEVED',
    INVOICES__DATA_MERGE: 'INVOICES__DATA_MERGE',
    INVOICES__DATA_CLEAR: 'INVOICES__DATA_CLEAR',
    INVOICES__CLEAR: 'INVOICES__CLEAR',

    COUNTER__CURRENT_RETRIEVED: 'COUNTER__CURRENT_RETRIEVED',
    COUNTER__START_DATE: 'COUNTER__START_DATE',
    COUNTER__END_DATE: 'COUNTER__END_DATE',
    COUNTER__HISTORY_MERGE: 'COUNTER__HISTORY_MERGE',
    COUNTER__HISTORY_CLEAR: 'COUNTER__HISTORY_CLEAR',
    COUNTER__HISTORY_CHART_RETRIEVED: 'COUNTER__HISTORY_CHART_RETRIEVED',
    COUNTER__HISTORY_CHART_CLEAR: 'COUNTER__HISTORY_CHART_CLEAR',
    COUNTER__CLEAR: 'COUNTER__CLEAR',
    COUNTER__LOADING_START: 'COUNTER__LOADING_START',
    COUNTER__LOADING_DONE: 'COUNTER__LOADING_DONE',

    COUNTER__CHOOSE_CURRENT_MANUAL: 'COUNTER__CHOOSE_CURRENT_MANUAL',
    COUNTER__CLEAR_CURRENT_MANUAL: 'COUNTER__CLEAR_CURRENT_MANUAL',

    SERVICES__DATA_RETRIEVED: 'SERVICES__DATA_RETRIEVED',
    SERVICES__LOADING_START: 'SERVICES__LOADING_START',
    SERVICES__LOADING_DONE: 'SERVICES__LOADING_DONE',
    SERVICES__CLEAR: 'SERVICES__CLEAR',

    EDIT_EMAIL_FORM__START_SENDING: 'EDIT_EMAIL_FORM__START_SENDING',
    EDIT_EMAIL_FORM__END_SENDING: 'EDIT_EMAIL_FORM__END_SENDING',
    EDIT_EMAIL_FORM__MESSAGE_RETRIEVED: 'EDIT_EMAIL_FORM__MESSAGE_RETRIEVED',
    EDIT_EMAIL_FORM__ERROR_SENDING: 'EDIT_EMAIL_FORM__ERROR_SENDING',
    EDIT_EMAIL_FORM__CLEAR: 'EDIT_EMAIL_FORM__CLEAR',

    MANUAL_COUNTER_FORM__START_SENDING: 'MANUAL_COUNTER_FORM__START_SENDING',
    MANUAL_COUNTER_FORM__END_SENDING: 'MANUAL_COUNTER_FORM__END_SENDING',
    MANUAL_COUNTER_FORM__MESSAGE_RETRIEVED:
        'MANUAL_COUNTER_FORM__MESSAGE_RETRIEVED',
    MANUAL_COUNTER_FORM__ERROR_SENDING: 'MANUAL_COUNTER_FORM__ERROR_SENDING',
    MANUAL_COUNTER_FORM__CLEAR: 'MANUAL_COUNTER_FORM__CLEAR',

    ORDERS_ACTIVE__MERGE: 'ORDERS_ACTIVE__MERGE',
    ORDERS_ACTIVE__LOADING_START: 'ORDERS_ACTIVE__LOADING_START',
    ORDERS_ACTIVE__LOADING_DONE: 'ORDERS_ACTIVE__LOADING_DONE',
    ORDERS_ACTIVE__CLEAR: 'ORDERS_ACTIVE__CLEAR',
    ORDERS_ACTIVE__ADD_NEW: 'ORDERS_ACTIVE__ADD_NEW',

    ORDERS_HISTORY__MERGE: 'ORDERS_HISTORY__MERGE',
    ORDERS_HISTORY__LOADING_START: 'ORDERS_HISTORY__LOADING_START',
    ORDERS_HISTORY__LOADING_DONE: 'ORDERS_HISTORY__LOADING_DONE',
    ORDERS_HISTORY__CLEAR: 'ORDERS_HISTORY__CLEAR',
    ORDERS_HISTORY__MARK: 'ORDERS_HISTORY__MARK',

    ORDERS_TYPES__RETRIEVED: 'ORDERS_TYPES__RETRIEVED',
    ORDERS_TYPES__LOADING_START: 'ORDERS_TYPES__LOADING_START',
    ORDERS_TYPES__LOADING_DONE: 'ORDERS_TYPES__LOADING_DONE',

    FILE_STORAGE__ADD_FILE: 'FILE_STORAGE__ADD_FILE',
    FILE_STORAGE__DROP_FILE: 'FILE_STORAGE__DROP_FILE',
    FILE_STORAGE__CLEAR: 'FILE_STORAGE__CLEAR',
    FILE_STORAGE__LOADING_START: 'FILE_STORAGE__LOADING_START',
    FILE_STORAGE__LOADING_DONE: 'FILE_STORAGE__LOADING_DONE',

    COOKIES__ACCEPT: 'COOKIES__ACCEPT',
    COOKIES__CHECK: 'COOKIES__CHECK',
};

export default actionTypes;
