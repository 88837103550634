import axiosIntegration from '../utils/axiosIntegration.js';
import { SCROLL_LOAD_LIMIT } from '../constants/pagination.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default ({ page, isActive = false }) =>
    async (dispatch, getState) => {
        const clientID = getState().global.auth.get('clientId');

        if (!page) {
            dispatch({
                type: isActive
                    ? actionTypes.ORDERS_ACTIVE__LOADING_START
                    : actionTypes.ORDERS_HISTORY__LOADING_START,
            });
        }

        const { data, status } = await axiosIntegration({
            method: 'post',
            url: 'api/v1/uk/MPGetIncidentList',
            data: {
                clientID,
                IsActive: isActive,
                page: page || 1,
                countOnPage: SCROLL_LOAD_LIMIT,
            },
        });

        if (status === OK && data.success === true) {
            dispatch({
                type: isActive
                    ? actionTypes.ORDERS_ACTIVE__MERGE
                    : actionTypes.ORDERS_HISTORY__MERGE,
                payload: data.data,
            });
        }

        return dispatch({
            type: isActive
                ? actionTypes.ORDERS_ACTIVE__LOADING_DONE
                : actionTypes.ORDERS_HISTORY__LOADING_DONE,
        });
    };
