import React from 'react';
import classNames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

const CustomModal = ({ isOpen, toggle, className, title, children, size }) => {
    const elementClasses = classNames('custom-modal', className);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className={elementClasses}
            size={size}
        >
            <ModalBody>
                <div className="custom-modal__header">
                    <div className="custom-modal__title">{title}</div>
                    <div
                        className="custom-modal__close"
                        onClick={toggle}
                    />
                </div>
                <div className="custom-modal__body">{children}</div>
            </ModalBody>
        </Modal>
    );
};

CustomModal.propTypes = {
    size: PropTypes.oneOf(['lg', 'md', 'sm']),
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string,
};

CustomModal.defaultProps = {
    size: 'md',
    className: '',
    title: '',
    isOpen: false,
};

export default CustomModal;
