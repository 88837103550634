import React, { Fragment, useState } from 'react';
import CustomModal from '../customModal/customModal.js';
import MobileAppLinks from '../mobileAppLinks/mobileAppLinks.js';

const NavBarMobileApp = ({ className }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Fragment>
            <span
                className={`nav-bar-mobile-app__btn ${className}`}
                onClick={toggle}
            >
                Мобильное приложение
            </span>
            <CustomModal
                className="nav-bar-mobile-app__modal"
                isOpen={isOpen}
                toggle={toggle}
                title="Мобильное приложение"
            >
                <div className="nav-bar-mobile-app__modal-body">
                    <MobileAppLinks withQr />
                    <div className="nav-bar-mobile-app__modal-text">
                        За оплату услуг через мобильное приложение ProHome может
                        взыматься комиссия.
                    </div>
                </div>
            </CustomModal>
        </Fragment>
    );
};

NavBarMobileApp.defaultProps = {
    className: '',
};

export default NavBarMobileApp;
