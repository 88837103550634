import React from 'react';
import SkeletonLine from '../skeletonLine/skeletonLine.js';
import { PAGINATION_LIMIT } from '../../constants/pagination.js';

const NewsSkeleton = () => {
    return Array(PAGINATION_LIMIT)
        .fill(true)
        .map((item, idx) => (
            <div
                className="col-12 faq-list__item"
                key={idx}
            >
                <div className="faq-list__item-box-skeleton">
                    <div className="faq-list__item-question-skeleton">
                        <SkeletonLine />
                    </div>
                </div>
            </div>
        ));
};

export default NewsSkeleton;
