import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import isTable from '../../utils/isTable.js';

const MobileSwitch = ({
    renderFirstComponent,
    renderSecondComponent,
    firstLabel,
    secondLabel,
}) => {
    const [showFirst, setShowFirst] = useState(true);

    const openFirst = () => setShowFirst(true);
    const openSecond = () => setShowFirst(false);

    const firstBtnClasses = classNames('btn', {
        show: showFirst,
    });

    const secondBtnClasses = classNames('btn', {
        show: !showFirst,
    });

    if (!isTable()) {
        return (
            <Fragment>
                {renderFirstComponent()}
                {renderSecondComponent()}
            </Fragment>
        );
    }
    return (
        <Fragment>
            <div className="col-12">
                <div className="btn-group mobile-switch__btn-box">
                    <button
                        className={firstBtnClasses}
                        onClick={openFirst}
                    >
                        {firstLabel}
                    </button>
                    <button
                        className={secondBtnClasses}
                        onClick={openSecond}
                    >
                        {secondLabel}
                    </button>
                </div>
            </div>
            {showFirst ? renderFirstComponent() : renderSecondComponent()}
        </Fragment>
    );
};

export default MobileSwitch;
