import React from 'react';
import { initStoreAndHistory } from '../store.js';
import initComplexes from '../actions/api.initComplexes.js';
import updateToken from '../actions/auth.updateToken.js';
import cookiesCheck from '../actions/cookies.check.js';

export default App => {
    const storeAndHistory = initStoreAndHistory({});

    storeAndHistory.store.dispatch(initComplexes());
    storeAndHistory.store.dispatch(updateToken());
    storeAndHistory.store.dispatch(cookiesCheck());

    return class PsnWebRedux extends React.Component {
        render() {
            return (
                <App
                    store={storeAndHistory.store}
                    history={storeAndHistory.history}
                />
            );
        }
    };
};
